import { Fragment, useCallback, useEffect, useState } from "react";
import "../../../assets/css/SpinWheel.css";
import BackgroundGame from "../../../components/BackgroundPopup/BackgroundGame";
import { useNavigate, useParams } from "react-router-dom";
import {
  LUOT_QUAY,
  userLocal,
} from "../../../services/localService/localService";
import PopupReceiveGift from "../../../components/Popup/PopupGift";
import { setAuthorization } from "../../../services/apiService/configURL";
import { GameServices } from "../../../services/apiService/GameService";
import { GameDetailsModel } from "../../../models/GameModel";
import PopupError from "../../../components/Popup/PopupError";
import MainSpinWheel from "../../../components/Game/MainSpinWheel";

export default function NewSpinWheel() {
  const [game, setBackgroundGame] = useState<GameDetailsModel>();
  const [isSpinning, setIsSpinning] = useState<boolean>(false);
  const [isAnnounce, setIsAnnounce] = useState<boolean>(false);
  const [transformBox, settransformBox] = useState<string>("");
  const [dataCallUrbox, setDataCallUrbox] = useState<number>(0);
  const [gift, setGift] = useState<any>();
  const [win, setwin] = useState(0);
  const [isError, setIsError] = useState<boolean>(false);
  const [messError, setMessError] = useState<string>("Error");
  const [segments, setsegments] = useState<any>();
  const [isOpenPopup, setIsPopup] = useState<boolean>(false);
  const [lineId, setLineId] = useState();
  const [popUpGift, setPopupGift] = useState("no");
  let navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { campaign_id, sale_id } = useParams<string>();
  let { token } = userLocal.get();
  const [isLoadButton, setIsLoadButton] = useState(false);
  const [isCount, setIsCount] = useState(false);
  setAuthorization(token);

  useEffect(() => {
    setAuthorization(token);
    setIsLoading(true);
    getCalculator(sale_id, campaign_id, true);
  }, []);
  const interleave: any = ([x, ...xs]: any, ys: any) =>
    x ? [x, ...interleave(ys, xs)] : ys;
  const [isDisable, setIsDisable] = useState(false);
  const runWheel = () => {
    setIsDisable(true);
    if (!isDisable) {
      setIsSpinning(true);
      var array_toado = [
        { id: 1, toado: 20 },
        { id: 2, toado: -25 },
        { id: 3, toado: -70 },
        { id: 4, toado: -115 },
        { id: 5, toado: -160 },
        { id: 6, toado: -205 },
        { id: 7, toado: -250 },
        { id: 8, toado: -295 },
      ];
      if (win !== 0) {
        var item = array_toado?.find((i) => i.id === win);
        let toado: number | undefined = item?.toado;
        settransformBox("rotate(" + ((toado as number) + 360 * 6) + "deg)");
        var element = document.getElementById("mainbox") as HTMLElement;
        countDraw();
        setTimeout(function () {
          element.classList.remove("animate");
          setIsSpinning(false);
          redirectToWheelResult(); // set qua lun phần thưởng ko dừng
          setIsPopup(true);
        }, 6000);
      }
    }
  };
  const redirectToWheelResult = () => {
    settransformBox("rotate(" + 0 + "deg)");
    setIsAnnounce(!isAnnounce);
  };
  const getCalculator = (
    sale_id: string | undefined,
    campaign_id: string | undefined,
    status: boolean | undefined
  ) => {
    const params = {
      sale_id: sale_id,
      campaign_id: campaign_id,
    };
    GameServices.CalculatorWheelApi(params)
      .then((res: any) => {
        if (res.remaining_draw === 0) {
          setIsError(true);
          setMessError("Bạn đã hết lượt chơi !!!");
        } else {
          setPopupGift("no");
          setDataCallUrbox(res.remaining_draw);
          setsegments(res.lucky_draw_list.list_lucky_draw[0].list_draw);
          if (status) {
            setBackgroundGame(res.lucky_draw_list.game_data);
          }
          let itemWin = res.lucky_draw_list.list_lucky_draw[0].list_win[0];
          setLineId(itemWin?.line_id);
          let codeWin = itemWin.gift_code;
          let segmentsTemp = res.lucky_draw_list.list_lucky_draw[0].list_draw;
          localStorage.setItem(
            "GAME_DATA_LOCAL",
            JSON.stringify(res.lucky_draw_list.game_data)
          );
          localStorage.setItem("GIFT_WHEEL", JSON.stringify(itemWin));
          localStorage.setItem(LUOT_QUAY, res.remaining_draw);
          setGift(itemWin);
          const a = segmentsTemp.findIndex((x: any) => x.gift_code === codeWin);
          setwin(a + 1);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
        console.log(err);
      });
  };
  const countDraw = async () => {
    const data = {
      sale_id: sale_id,
      campaign_id: campaign_id,
      line_id: lineId,
    };

    GameServices.CountDrawApi(data)
      .then((res: any) => {
        localStorage.setItem(LUOT_QUAY, res.remaining_draw);
        if (res.remaining_draw === 0) {
          const dataParams = {
            sale_id: sale_id,
            campaign_id: campaign_id,
          };
          GameServices.SendGiftApi(dataParams)
            .then((res) => {})
            .catch((err) => {
              console.log(err);
            });
        }
        localStorage.setItem("LUCKY_CODE", JSON.stringify(res));
        setDataCallUrbox(res.remaining_draw);
        setIsCount(true);
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
        console.log(err);
      });
  };
  const handleAgree = () => {
    setIsLoadButton(true);
    setIsCount(false);
    getCalculator(sale_id, campaign_id, false);
    if (!isLoadButton) {
      const luot_quay = localStorage.getItem(LUOT_QUAY);
      if (luot_quay === "0") {
        navigation("/list-gift");
      } else {
        setPopupGift("change");
        setIsPopup(false);
        setIsLoadButton(false);
        setIsDisable(false);
      }
    }
  };
  const handleCloseError = () => {
    navigation("/games");
    setIsError(false);
  };
  const BorderRotation = useCallback(() => {
    return (
      <div className="absolute z-10 -bottom-[1px] w-full">
        <img src={game?.rotation_frame_image_url} className="w-full" />
      </div>
    );
  }, [game]);
  const BackgroundRotation = useCallback(() => {
    return (
      <div className="absolute top-1/2 -translate-y-1/2 w-full">
        <img
          src={game?.wheel_image_url}
          className="w-[93%] relative z-0 left-1/2 -translate-x-1/2"
        />
      </div>
    );
  }, [game]);
  const LabelRotation = useCallback(() => {
    return (
      <div
        className="absolute bottom-3 text-[12px] left-1/2 -translate-x-1/2 w-8/12 text-center rounded-lg"
        style={{
          backgroundColor: game?.button_color,
          border: `2px solid ${game?.button_border_color}`,
          color: game?.button_text_color,
        }}
      >
        {dataCallUrbox > 0 ? (
          <>
            Bạn có{" "}
            <span
              className="text-[18px] font-bold-so text-[#FDCA00]"
              style={{ color: game?.light_color }}
            >
              {dataCallUrbox}
            </span>{" "}
            Lượt xác định trúng thưởng
          </>
        ) : (
          "Hết lượt quay"
        )}
      </div>
    );
  }, [dataCallUrbox, isCount, game]);
  const ButtonRotation = useCallback(() => {
    return (
      <button className="relative">
        <img src={game?.rotation_footer_image_url} />
      </button>
    );
  }, [game]);

  return (
    <div>
      {/* {isLoading ? (
        <div className="min-h-screen w-full bg-black">
          <Loading />
        </div>
      ) : null} */}
      <Fragment>
        {segments && (
          <BackgroundGame img={game?.background_image_url}>
            <div className="absolute top-0 flex justify-center z-0 w-full px-5">
              <img
                src={game?.title_image_url}
                loading="lazy"
                className="w-10/12"
              />
            </div>
            {isOpenPopup ? null : (
              <div>
                <MainSpinWheel
                  runWheel={runWheel}
                  isAnnounce={isAnnounce}
                  transformBox={transformBox}
                  segments={segments}
                  isSpinning={isSpinning}
                  game={game!}
                  borderRotation={<BorderRotation />}
                  backgroundRotaion={<BackgroundRotation />}
                  buttonRotation={<ButtonRotation />}
                  label={<LabelRotation />}
                />
              </div>
            )}
            <PopupReceiveGift
              gift={gift}
              isShow={isOpenPopup}
              handleAgree={handleAgree}
              isLoadButton={isLoadButton}
            />
          </BackgroundGame>
        )}
      </Fragment>
      <PopupError
        labelBtn={"Đồng ý"}
        messError={messError}
        handleClose={handleCloseError}
        isShow={isError}
      />
    </div>
  );
}
