import LOGO_MVC from "../../assets/image/Home/logo-mvctech.png";

export default function Footer() {
  return (
    <div className="bg-[#ECF3FF] fixed bottom-0 font-semibold-so text-[7px] text-[#2A2A2A] h-[10px] w-full">
      <div className="flex justify-center items-center h-full">
        <span className="flex items-center">
          <img
            src={LOGO_MVC}
            loading="lazy"
            width={30}
            height={20}
            alt="Image"
          />
          &nbsp;
        </span>
        <div className="mt-[2px]">
          |<span className="font-light-so">&nbsp;Power by</span>
          &nbsp;MVC Tech
        </div>
      </div>
    </div>
  );
}
