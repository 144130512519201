import BACK from "../../assets/image/Header/back-header.png";
import EXCHANGE_POINT from "../../assets/image/GameExchangePoint/mypoint.png";
import ICON_COIN from "../../assets/image/Home/icon-coin.png";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ItemExchangePoint from "./ItemExchangePoint";
import { useDispatch } from "react-redux";
import { getAllBannerHome } from "../../redux/slices/HomeSlice";
import { AppDispatch } from "../../redux/stores/store";
import { setAuthorization } from "../../services/apiService/configURL";
import { userLocal } from "../../services/localService/localService";
import { ExchangeGift, Gifts } from "../../models/HomePage";
import { ListReceiptServices } from "../../services/apiService/ListReceiveService";
import { ListGiftExchange } from "../../models/ListReceiveCustomer";
import { Pagination } from "antd";
import SkelatonExchangePoint from "../../components/Skelaton/SkelatonExchangePoint";
import { useQuery } from "react-query";
import { homeServices } from "../../services/apiService/homeService";
import HEADER_CHANGEGIFT from "../../assets/image/GameExchangePoint/header-changegift.png";
import BTN_DISABLE_VOUCHER from "../../assets/image/GameExchangePoint/btndisvoucher.png";
import BTN_VOUCHER from "../../assets/image/GameExchangePoint/btnvoucher.png";
import BTN_GIFT from "../../assets/image/GameExchangePoint/btngift.png";
import BTN_DISABLE_GIFT from "../../assets/image/GameExchangePoint/disbtngift.png";

export default function ExchangePoint() {
  const [current, setCurrent] = useState("2");
  const navigation = useNavigate();
  let { token } = userLocal.get();
  setAuthorization(token);
  const { back } = useParams();
  const [totalItem, setTotalItem] = useState<number>(0);
  const [level_id, setLevelId] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [reward_type, setRewardType] = useState<string>("voucher");
  const [order_by, setOrderBy] = useState<string>("asc");
  const [selectedItem, setSelectedItem] = useState<any>(0);
  const [listGift, setListGift] = useState<ListGiftExchange>();
  const [listTab, setListTab] = useState<any>();
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getAllBannerHome());
    setListGift([] as any);
    ListReceiptServices.GetListExchangeGift(
      reward_type,
      page,
      limit,
      order_by,
      level_id
    )
      .then((res: any) => {
        setListGift(res);
        const level_all = [
          {
            level_id: 0,
            level_name: "Tất cả",
            level_tag_name: "Tất cả",
          },
        ];
        const stateReward = [...level_all, ...res?.rewards];
        setListTab(stateReward);
        setTotalItem(res?.total_item);
      })
      .catch((err) => {
        console.log(err);
      });
    window.scrollTo(0, 0);
  }, []);
  const [checkEmpty, setCheckEmpty] = useState(false);

  const getListExchange = (
    reward_type: string,
    page: number,
    order_by: string,
    limit: number,
    level_id: number
  ) => {
    ListReceiptServices.GetListExchangeGift(
      reward_type,
      page,
      limit,
      order_by,
      level_id
    )
      .then((res: any) => {
        setListGift(res);
        const check = res?.rewards?.filter((x: any) => x?.gifts?.length === 0);
        setCheckEmpty(check);
        setTotalItem(res?.total_item);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const hanldeClick = (e: any, status_gifts: string) => {
    window.scrollTo(0, 0);
    setPage(1);
    setListGift([] as any);
    setSelectedItem(0);
    setCurrent(e);
    setRewardType(status_gifts);
    if (status_gifts === "voucher") {
      setLevelId(0);
      getListExchange(status_gifts, 1, order_by, limit, 0);
    } else {
      setLevelId(1);
      getListExchange(status_gifts, 1, order_by, limit, 1);
    }
  };
  const handleBack = () => {
    navigation(`${back ? "/" + back : "/"}`);
  };
  const handleSelect = (item: ExchangeGift) => {
    setListGift([] as any);
    setPage(1);
    setSelectedItem(item?.level_id);
    setLevelId(item?.level_id);
    getListExchange(reward_type, 1, order_by, limit, item.level_id);
  };
  const handleD = (e: number) => {
    setListGift([] as any);
    setPage(e);
    window.scrollTo(0, 0);
    getListExchange(reward_type, e, order_by, limit, level_id);
  };
  const { data } = useQuery<any>({
    queryKey: ["getCampsaign"],
    queryFn: () => homeServices.homeApi(),
  });
  console.log(current);

  let a = false;
  return (
    <div className="">
      <div className="bg-[#FFF9F4] relative h-[140px]">
        <div className="absolute top-0">
          <img src={HEADER_CHANGEGIFT} />
        </div>
        <div className="flex px-3 pt-3 pb-1 relative items-center">
          <div className="" onClick={handleBack}>
            <img src={BACK} className="w-7" />
          </div>
          <div className="absolute left-1/2 -translate-x-1/2 font-semibold-so text-[20px] text-white">
            Đổi điểm nhận quà
          </div>
        </div>
        <div className="mt-2 rounded-t-2xl h-14 relative">
          <div className="flex justify-between bg-[#B5E0EE] h-14 w-full rounded-t-2xl px-5 relative">
            <div className="flex items-center -mt-4">
              <div className="text-[12px] flex items-center">
                <div className="mr-2">
                  <img src={EXCHANGE_POINT} />
                </div>
                Số điểm bạn đang có
              </div>
            </div>
          </div>
          <div className="flex items-center absolute right-4 top-4">
            <div className="-mt-4">
              <img src={ICON_COIN} />
            </div>
            <div className="flex items-center text-[16px] -mt-3 relative text-[#2D4E68]">
              <span className="text-[24px] -mt-1 mx-1 text-[#005CB4]">
                {data?.points}
              </span>{" "}
              Pepcoin
            </div>
          </div>
        </div>
        <div className="relative -top-4 w-full bg-[#FFF9F4] rounded-t-3xl">
          <div className="pt-3 px-3 w-full">
            <div className="tab-receive flex justify-between items-center align-middle px-2">
              <div className="p-1" onClick={(e) => hanldeClick("2", "voucher")}>
                {current === `2` ? (
                  <img src={BTN_VOUCHER} />
                ) : (
                  <img src={BTN_DISABLE_VOUCHER} />
                )}
              </div>
              <div
                className="p-1"
                onClick={(e) => hanldeClick("1", "physical")}
              >
                {current === `1` ? (
                  <img src={BTN_GIFT} />
                ) : (
                  <img src={BTN_DISABLE_GIFT} />
                )}
              </div>
              {/*<div className="flex justify-center flex-auto">
                <button
                  key="2"
                  id={"2"}
                  disabled={current === `${2}`}
                  onClick={(e) => hanldeClick(e, "voucher")}
                  className="font-semibold-mon mt-[6px]"
                >
                  Voucher
                </button>
              </div>
              <div className="flex justify-center flex-auto">
                <button
                  key="1"
                  id={"1"}
                  disabled={current === `${1}`}
                  onClick={(e) => hanldeClick(e, "physical")}
                  className="font-semibold-mon mt-[6px]"
                >
                  Quà vật lý
                </button>
              </div>*/}
            </div>
            <div className="my-2">
              <div className="overhand pb-1">
                {listTab?.map((res: ExchangeGift, index: number) => {
                  return (
                    <div
                      className="border-tab-exchange w-full py-1 px-5 mr-2 font-medium-so flex"
                      key={index}
                      onClick={() => handleSelect(res)}
                      style={{
                        backgroundColor:
                          selectedItem?.toString() === res?.level_id?.toString()
                            ? "#5BC2DC"
                            : "#ffffff",
                        color:
                          selectedItem?.toString() === res?.level_id?.toString()
                            ? "#ffffff"
                            : "",
                      }}
                    >
                      {res.level_tag_name}{" "}
                      {res.level_id !== 0 && (
                        <span className="flex pl-1 pr-4">
                          Pepcoin
                          <img src={ICON_COIN} className="ml-1" />
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            {listGift?.rewards ? (
              <>
                {(listGift?.rewards?.length as number) !== 0 ? (
                  <>
                    {listGift?.rewards?.map(
                      (res: ExchangeGift, index: number) => {
                        if ((res?.gifts?.length as number) !== 0) {
                          a = true;
                        }
                        return (
                          <div key={index}>
                            <div className="text-[#2D4E68] font-semibold-so border-title-exchangegift">
                              {res.level_name}
                            </div>
                            <div className="grid grid-cols-2 gap-2 mb-5">
                              {res?.gifts?.map((item: Gifts, index: number) => (
                                <div key={index}>
                                  <ItemExchangePoint
                                    gift_vendor_logo_url={
                                      item?.gift_vendor_logo_url
                                    }
                                    gift_id={item?.gift_id}
                                    gift_image_url={item?.gift_image_url}
                                    reward_image_url={item?.reward_image_url}
                                    gift_name={item?.gift_name}
                                    required_points={item?.required_points}
                                    reward_id={item?.reward_id}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </>
                ) : (
                  <div className="mt-20 text-center">
                    Kho quà vật lý đang được nâng cấp. Vui lòng quay lại sau
                  </div>
                )}
              </>
            ) : (
              <div className="grid grid-cols-2 gap-1 mb-5">
                <SkelatonExchangePoint />
                <SkelatonExchangePoint />
                <SkelatonExchangePoint />
                <SkelatonExchangePoint />
                <SkelatonExchangePoint />
                <SkelatonExchangePoint />
                <SkelatonExchangePoint />
                <SkelatonExchangePoint />
              </div>
            )}
          </div>
          <div className="flex justify-center py-16">
            {totalItem > 10 && (
              <Pagination current={page} total={totalItem} onChange={handleD} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
