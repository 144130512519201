import GOLDEN from "../../../../assets/image/Game/DragonSnake/golden.png";
import SILVER from "../../../../assets/image/Game/DragonSnake/silver.png";
import GOLDEN_SPECIAL from "../../../../assets/image/Game/DragonSnake/golden_special.png";
import GOLDEN_EXTRA from "../../../../assets/image/Game/DragonSnake/golden_extra.png";
import { Fragment } from "react/jsx-runtime";

export default function PointBoard({ current }: { current: number }) {
  return (
    <div className="fixed top-[45%] -translate-y-1/2 left-0 z-10">
      <div className="relative bg-white w-10 py-4 rounded-tr-[20px] rounded-br-[20px]">
        <div className="w-3 bg-[#013BE2] border_board-point relative rounded-full left-1/2 -translate-x-1/2">
          {[...Array(10)].map((_, index) => {
            return (
              <div key={index} className="relative bottom-0 h-11">
                {index == 9 ? (
                  <Fragment>
                    {9 - current !== index ? (
                      <div className="w-4 -ml-[2px] relative -bottom-6">
                        <img src={SILVER} className="w-full" />
                      </div>
                    ) : (
                      <div className="w-10 relative left-1/2 -translate-x-1/2 translate-y-[20%] ">
                        <img src={GOLDEN_SPECIAL} className="w-10 relative" />
                        <div className="absolute text-white text-[24px] font-semibold-so top-[40%] -translate-y-1/2 left-1/2 -translate-x-1/2">
                          {current}
                        </div>
                      </div>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {9 - current === index ? (
                      <div className="w-10 relative left-1/2 -translate-x-1/2 -top-[2%] translate-y-[15%] ">
                        <img src={GOLDEN_SPECIAL} className="w-10 relative" />
                        <div className="absolute text-white text-[24px] font-semibold-so top-[46%] -translate-y-1/2 left-1/2 -translate-x-1/2">
                          {current}
                        </div>
                      </div>
                    ) : (
                      <div className="">
                        {index < 9 - current && index !== 10 ? (
                          <div
                            className={`${
                              index === 0 || index === 4 || index === 6
                                ? "w-6 -ml-[6px] top-[0px]"
                                : "w-4 -ml-[2px] top-[4px]"
                            } relative`}
                          >
                            <img
                              src={
                                index === 0 || index === 4 || index === 6
                                  ? GOLDEN_EXTRA
                                  : GOLDEN
                              }
                              className="w-full"
                            />
                          </div>
                        ) : (
                          <div className="w-4 -ml-[2px] relative -bottom-6">
                            <img src={SILVER} className="w-full" />
                          </div>
                        )}
                      </div>
                    )}
                  </Fragment>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
