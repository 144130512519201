import { Fragment } from "react/jsx-runtime";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import HeaderBack from "../../../../components/HeaderBack/HeaderBack";
import PopupLogin from "../../../../popup/PopupLogin";
import CLOSE from "../../../../assets/image/Home/cancel-popup.png";
import BANNER_CLOUD from "../../../../assets/image/Game/DragonSnake/banner_cloud.png";
import BACKHEADER from "../../../../assets/image/Header/back-header.png";

export default function TCGame() {
  let campaign_type = window.location.pathname.split("/")[1];
  const { id } = useParams();
  const prizeGame = JSON.parse(
    localStorage.getItem("DATA_LEADER_BOARD") || "{}"
  );
  console.log(prizeGame);
  const navigation = useNavigate();
  const [isShowDisable, setIsShowDisable] = useState(false);
  const handleJoinGame = () => {
    navigation(`/dragon_snake?id=${id}`);
  };
  const handleClickGift = (e: string) => {
    if (!prizeGame?.disable) {
      navigation(e);
    }
  };
  const handleClose = () => {
    setIsShowDisable(false);
  };
  return (
    <Fragment>
      <div className="relative top-0 bg-[#FFF9F4]" style={{}}>
        <div
          className="relative top-0 rounded-b-3xl bg-[#003596]"
          style={{ backgroundColor: prizeGame?.background_color }}
        >
          <div
            className="w-full h-20 relative"
            style={{
              backgroundColor: prizeGame?.background_color
                ? prizeGame?.background_color
                : "#003596",
            }}
          >
            <img src={prizeGame?.header_details_image_url} className="h-20" />
          </div>
          <div className="relative -top-14 bg-[#003596]">
            <img
              src={prizeGame?.banner_image_url}
              loading="lazy"
              className="rounded-3xl w-full"
            />
          </div>
          <div className="absolute top-5 px-5 w-full">
            <HeaderBack
              name=""
              navigate="/dragon_snake?id=2"
              imageBack={
                prizeGame?.button_back_image_url
                  ? prizeGame?.button_back_image_url
                  : (BACKHEADER as string)
              }
            />
          </div>
          <div className="relative bg-[#003596]">
            <div
              className="uppercase text-[20px] -mt-12 line-clamp-2 px-3 font-semibold-so pb-1 text-white"
              style={{ color: prizeGame?.campaign_title_color }}
            >
              {prizeGame?.campaign_name
                ? prizeGame?.campaign_name
                : "Rồng Rắn Lên Mây - Hái Lộc Liền Tay"}
            </div>
          </div>
          <div className="flex justify-end mt-1">
            <div className="pl-[6px] rounded-tl-3xl rounded-br-2xl relative bottom-0 right-0 w-44">
              <div className="relative right-0 z-0 w-full">
                <img
                  src={
                    prizeGame?.label_date_image_url
                      ? prizeGame?.label_date_image_url
                      : BANNER_CLOUD
                  }
                  className="w-full"
                />
              </div>
              <div
                className="absolute w-full font-semibold-so left-1/2 -translate-x-1/2 h-full top-2 z-10 font-light-so rounded-br-2xl py-1 mt-1 text-center rounded-tl-3xl"
                style={{
                  color: "#0013E9",
                }}
              >
                {prizeGame?.periodic_time}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="pt-5 -mt-[1px] bg-[#FFF9F4]"
        style={{ backgroundColor: prizeGame?.background_color }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: prizeGame?.content_details,
          }}
          style={{ color: prizeGame?.campaign_text_details_color as string }}
          className="font-light-so px-6 text-black pb-44 point-ul line-table text-[14px]"
        ></div>
        <div
          className="flex justify-center flex-col pb-1 fixed bottom-0 w-full px-6 "
          style={{ backgroundColor: prizeGame?.background_color }}
        >
          <div className="grid grid-cols-2 gap-2">
            <div className="col-span-1">
              <button
                onClick={() =>
                  handleClickGift(`/${campaign_type}/list-receive/gift/${id}`)
                }
              >
                <img src={prizeGame?.prizes_button_image_url} />
              </button>
            </div>
            <div className="col-span-1">
              <button
                onClick={() =>
                  handleClickGift(`/${campaign_type}/list-receive/prize/${id}`)
                }
              >
                <img src={prizeGame?.gifts_button_image_url} />
              </button>
            </div>
          </div>
          <div className="mt-3">
            <button
              onClick={handleJoinGame}
              className="w-full flex justify-center"
            >
              <img src={prizeGame?.button_join_image_url} className="" />
            </button>
          </div>
        </div>
        <div className="pt-4 bg-[#FFF9F4]">
          <div
            dangerouslySetInnerHTML={{
              __html: prizeGame?.content_details,
            }}
            className="font-light-so px-6 mb-44 point-ul line-table text-[14px]"
          ></div>
          <div className="flex justify-center flex-col pb-10 fixed bottom-0 w-full px-6 fade-background">
            <div className="grid grid-cols-2 gap-2">
              <div className="col-span-1">
                <button
                  className="w-full rounded-xl leading-5 py-2 shadow-item"
                  style={{
                    backgroundColor: "#2D4E68",
                    border: `2px solid #E8FAFF`,
                    color: "#ffffff",
                  }}
                  onClick={() =>
                    handleClickGift(`${`/list-receive-customer/gift/${id}?type_game=climbing_tower`}
                    `)
                  }
                >
                  {prizeGame?.campaign_type === "leader_board" ? (
                    <div className="py-[10px]">Bảng xếp hạng</div>
                  ) : (
                    <>
                      Danh sách
                      <br /> Quà tặng
                    </>
                  )}
                </button>
              </div>
              <div className="col-span-1">
                <button
                  className="w-full rounded-xl leading-5 py-2 shadow-item"
                  style={{
                    backgroundColor: "#81BEB4",
                    border: `2px solid #E8FAFF`,
                    color: "#ffffff",
                  }}
                  onClick={() =>
                    handleClickGift(
                      `${`/list-receive-customer/prize/${id}?type_game=climbing_tower`}`
                    )
                  }
                >
                  Danh sách
                  <br /> trúng thưởng
                </button>
              </div>
            </div>
            <div className="mt-3">
              <button
                className=" py-3 rounded-xl w-full shadow-item"
                style={{
                  backgroundColor: "#5BC2DC",
                  // backgroundColor: "#bdbdbd",
                  border: `2px solid #ffffff`,
                  color: "#ffffff",
                }}
                onClick={handleJoinGame}
              >
                Tham gia ngay
              </button>
            </div>
          </div>
        </div>
      </div>
      <PopupLogin isShow={isShowDisable}>
        <div className="py-5">
          <div className="uppercase text-[#2D4E68] text-center text-[28px] font-bold-so">
            Thông báo
          </div>
          <div className="py-5">
            Chờ đón nhiều Chương trình Khuyến Mãi siêu hot đang sắp diễn ra!
          </div>
          <div className="absolute -bottom-20 left-1/2 -translate-x-1/2">
            <img src={CLOSE} onClick={handleClose} />
          </div>
        </div>
      </PopupLogin>
    </Fragment>
  );
}
