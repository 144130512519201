import TEN_LUA from "../../assets/image/Popup/tenlua.png";
import PHONE from "../../assets/image/Popup/phone.png";
import ContactPhoneZalo from "../ContactPhoneZalo/ContactPhoneZalo";
import { useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import BTN_HOTLINE from "../../assets/image/Popup/bg-hotline.png";
import DRAGONLOADING from "../../assets/image/Game/DragonSnake/dragonloading.png";

export default function Waiting() {
  const { hotline } = JSON.parse(
    localStorage.getItem("HOME_DATA_LOCAL") || "{}"
  );
  const [percent, setPercent] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPercent((prevCount) => {
        if (prevCount >= 100) {
          setCount(count + 1);
          clearInterval(interval);
          return 100;
        }
        return prevCount + 1;
      });
    }, 80);

    return () => clearInterval(interval);
  }, []);
  const renderTime = ({ remainingTime }: any) => {
    if (remainingTime === 0) {
      return (
        <div className="timer text-[#4E9ABB] font-semibold-so text-[36px] font-bold-so">
          100%
        </div>
      );
    }
    return (
      <div className="timer">
        <span className="countdown-text text-[#4E9ABB] text-[36px] font-bold-so flex justify-center">
          {" "}
          <div className="text-center flex justify-center">{percent}%</div>
        </span>
      </div>
    );
  };
  return (
    <div className="background-result min-h-screen relative">
      <div className="absolute top-1/2 -translate-y-1/2 flex flex-col items-center ">
        <div className="font-bold-so text-[#ffffff] text-center flex">
          Hóa đơn đang được tải lên{" "}
          <div className="flex space-x-1 justify-center mt-4">
            <span className="sr-only">Loading...</span>
            <div className="h-1 w-1 bg-[#ffffff] rounded-full animate-bounce [animation-delay:-0.01s]"></div>
            <div className="h-1 w-1 bg-[#ffffff] rounded-full animate-bounce [animation-delay:-0.15s]"></div>
            <div className="h-1 w-1 bg-[#ffffff] rounded-full animate-bounce"></div>
          </div>
        </div>
        <div className="flex justify-center mt-0">
          <img src={TEN_LUA} className="w-9/12" />
        </div>
        <div className="font-regular-so text-[#ffffff] text-[14px] text-center px-5">
          Vui lòng đợi trong giây lát và đừng rời khỏi màn hình. Hệ thống đang
          xử lý
        </div>
        <div className="relative mt-16 bg-white rounded-full">
          <CountdownCircleTimer
            isPlaying
            duration={8}
            colors={["#4E9ABB", "#4E9ABB", "#4E9ABB", "#4E9ABB"]}
            colorsTime={[5, 6, 3, 0]}
            onComplete={() => ({ shouldRepeat: false, delay: 1 })}
            size={155}
          >
            {renderTime}
          </CountdownCircleTimer>
          <div className="absolute -z-50 left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 w-[300px]">
            <img src={DRAGONLOADING} className="w-[300px]" />
          </div>
        </div>
        <div className="relative top-10">
          <div className="relative">
            <img src={BTN_HOTLINE} className="relative" />
          </div>
          <div className="absolute flex items-center top-1/2 -translate-y-1/2 w-full justify-center">
            <div>
              <img src={PHONE} />
            </div>
            <div className="text-[#ffffff] font-light-so ml-2">
              Liên hệ Hotline{" "}
              <span className="font-medium-so">
                <a href={`tel:${hotline}`}>{hotline}</a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <ContactPhoneZalo />
    </div>
  );
}
