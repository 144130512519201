import WINDOW from "../../../assets/image/Game/Ghost/window.png";
import "../../../assets/css/Ghost.css";
import { useEffect, useState } from "react";
import BUTTON from "../../../assets/image/Game/Ghost/button.png";
import AUDIO from "../../../assets/audio/audio.mp3";
import { Howl } from "howler";
import { useNavigate, useParams } from "react-router-dom";
import { GameServices } from "../../../services/apiService/GameService";
import { GameDetailsModel } from "../../../models/GameModel";
import { LUOT_QUAY } from "../../../services/localService/localService";
import PopupError from "../../../components/Popup/PopupError";

export default function Ghost() {
  const [isShow, setIsShow] = useState(false);
  const navigation = useNavigate();
  const [isOpenGhost, setIsOpenGhost] = useState(-1);
  const [indexGift, setIndexGift] = useState(-1);
  const [game, setBackgroundGame] = useState<GameDetailsModel>();
  const [isStop, setTop] = useState<boolean>(false);
  const [dataCallUrbox, setDataCallUrbox] = useState<number>(0);
  const [gift, setGift] = useState<any>();
  const [isError, setIsError] = useState<boolean>(false);
  const [messError, setMessError] = useState<string>("Error");
  const [segments, setsegments] = useState<any>([]);
  const [lineId, setLineId] = useState();
  const [popUpGift, setPopupGift] = useState("no");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const { campaign_id, sale_id } = useParams<string>();
  useEffect(() => {
    if (!isShow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isShow]);
  useEffect(() => {
    getCalculator(sale_id, campaign_id);
    const test = [1, 0, 5, 6, 3, 2, 0];
    const a = test.findIndex((x) => x === 0);
  }, []);
  const getCalculator = (
    sale_id: string | undefined,
    campaign_id: string | undefined
  ) => {
    const params = {
      sale_id: sale_id,
      campaign_id: campaign_id,
    };
    GameServices.CalculatorWheelApi(params)
      .then((res: any) => {
        if (res.remaining_draw === 0) {
          setIsError(true);
          setMessError("Bạn đã hết lượt chơi !!!");
        } else {
          setPopupGift("no");
          setDataCallUrbox(res.remaining_draw);
          setsegments(res.lucky_draw_list.list_lucky_draw[0].list_draw);
          setBackgroundGame(res.lucky_draw_list.game_data);
          let itemWin = res.lucky_draw_list.list_lucky_draw[0].list_win[0];
          setLineId(itemWin?.line_id);
          let codeWin = itemWin.gift_code;
          let segmentsTemp = res.lucky_draw_list.list_lucky_draw[0].list_draw;
          localStorage.setItem(
            "GAME_DATA_LOCAL",
            JSON.stringify(res.lucky_draw_list.game_data)
          );
          localStorage.setItem("GIFT_WHEEL", JSON.stringify(itemWin));
          localStorage.setItem(LUOT_QUAY, res.remaining_draw);
          setGift(itemWin);
          const a = segmentsTemp.findIndex((x: any) => x.gift_code === codeWin);
          setIndexGift(a);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
        console.log(err);
      });
  };
  const handleUnset = (number: number) => {
    setIsDisable(true);
    if (!isDisable) {
      countDraw();
      setIsOpenGhost(number);
      const sound = new Howl({
        src: [AUDIO],
        autoplay: true,
        loop: false,
        volume: 0.5,
      });

      sound.play();
      setTimeout(() => {
        navigation(`/receive-gift-ghost/${campaign_id}/${sale_id}`);
      }, 5000);

      return () => {
        sound.stop();
      };
    }
  };
  const countDraw = async () => {
    const data = {
      sale_id: sale_id,
      campaign_id: campaign_id,
      line_id: lineId,
    };
    GameServices.CountDrawApi(data)
      .then((res: any) => {
        console.log(res);
        localStorage.setItem(LUOT_QUAY, res.remaining_draw);
        if (parseInt(res.remaining_draw) === 0) {
          // setTimeout(() => {
          const dataParams = {
            sale_id: sale_id,
            campaign_id: campaign_id,
          };
          GameServices.SendGiftApi(dataParams)
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
          // }, 7000);
        }
        localStorage.setItem("LUCKY_CODE", JSON.stringify(res));
        setDataCallUrbox(res.remaining_draw);
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
        console.log(err);
      });
  };
  const handleCloseError = () => {
    navigation("/games");
    setIsError(false);
  };
  return (
    <div
      style={{
        background: `url(${game?.background_image_url})`,
        width: "100vw",
        minHeight: "100vh",
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        position: "relative",
      }}
    >
      {!isError && (
        <div className="absolute bottom-10 bg-house">
          <div className="relative top-0 z-0">
            <img src={game?.machine_header_image_url} />
            {isOpenGhost !== -1 && (
              <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-20">
                <div className="relative top-0">
                  <img
                    src={game?.claw_image_url}
                    className="animation-tornado"
                  />
                  <div className="absolute top-0">
                    <img
                      src={game?.game_mute_items[0]}
                      className="animation-whiteghost"
                    />
                    <img
                      src={game?.game_mute_items[1]}
                      className="animation-whiteghost2"
                    />
                    <img
                      src={game?.game_mute_items[2]}
                      className="animation-whiteghost3"
                    />
                    <img
                      src={game?.game_mute_items[3]}
                      className="animation-whiteghost4"
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="absolute top-[40%] -translate-y-1/2 z-10 left-1/2 -translate-x-1/2">
              {(isOpenGhost === -1 || isOpenGhost === 1) && (
                <div
                  className="relative w-12 h-12 overflow-hidden"
                  style={{
                    backgroundColor: game?.light_color,
                  }}
                  onClick={() => handleUnset(1)}
                >
                  <img
                    src={game?.machine_body_image_url}
                    className="w-12 h-12 relative z-10"
                  />
                  <div className="absolute bottom-0 z-0">
                    <img
                      src={game?.game_items && game?.game_items[1]}
                      className="animation-cat"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="absolute top-[55%] -translate-y-1/2 z-10 left-1/2 -translate-x-1/2">
              {(isOpenGhost === -1 || isOpenGhost === 2) && (
                <div
                  className="relative w-12 h-12 overflow-hidden z-10"
                  style={{
                    backgroundColor: game?.light_color,
                  }}
                  onClick={() => handleUnset(2)}
                >
                  <img src={WINDOW} className="w-12 h-12 relative z-10" />
                  <div className="absolute bottom-0 right-0 z-0">
                    <img
                      src={game?.game_items && game?.game_items[0]}
                      className="animation-ghost2 z-10"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="absolute top-[68%] -translate-y-1/2 z-10 left-1/2 -translate-x-1/2">
              {(isOpenGhost === -1 || isOpenGhost === 3) && (
                <div
                  className="relative w-12 h-12 overflow-hidden"
                  style={{
                    backgroundColor: game?.light_color,
                  }}
                  onClick={() => handleUnset(3)}
                >
                  <img
                    src={game?.machine_body_image_url}
                    className="w-12 h-12 relative z-10"
                  />
                  <div className="absolute bottom-0 z-0">
                    <img
                      src={game?.game_items && game?.game_items[2]}
                      className="animation-cat"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="absolute top-[61%] -translate-y-1/2 z-10 left-[31%] -translate-x-1/2">
              {(isOpenGhost === -1 || isOpenGhost === 4) && (
                <div
                  className="relative w-12 h-12 overflow-hidden"
                  style={{
                    backgroundColor: game?.light_color,
                  }}
                  onClick={() => handleUnset(4)}
                >
                  <img
                    src={game?.machine_body_image_url}
                    className="w-12 h-12 relative z-10"
                  />

                  <div className="absolute bottom-0 z-0">
                    <img
                      src={game?.game_items && game?.game_items[1]}
                      className="animation-ghost"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="absolute top-[61%] -translate-y-1/2 z-10 right-[19%] -translate-x-1/2">
              {(isOpenGhost === -1 || isOpenGhost === 5) && (
                <div
                  className="relative w-12 h-12 overflow-hidden"
                  style={{
                    backgroundColor: game?.light_color,
                  }}
                  onClick={() => handleUnset(5)}
                >
                  <img
                    src={game?.machine_body_image_url}
                    className="w-12 h-12 relative z-10"
                  />
                  <div className="absolute bottom-0 z-0">
                    <img
                      src={game?.game_items && game?.game_items[1]}
                      className="animation-ghost2"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="absolute top-[73%] left-1/2 -translate-x-1/2">
              <img src={BUTTON} className="w-[100px]" />
              <div
                className="absolute top-1/2 -translate-y-1/2 text-center w-full text-[12px]"
                style={{
                  color: game?.text_color,
                }}
              >
                Bạn có{" "}
                <span
                  className="text-[12px]"
                  style={{ color: game?.light_color }}
                >
                  {dataCallUrbox}
                </span>{" "}
                lượt chơi
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="absolute z-0 bottom-0 animation-fence">
        <img src={game?.machine_footer_image_url} />
      </div>
      <PopupError
        labelBtn={"Đồng ý"}
        messError={messError}
        handleClose={handleCloseError}
        isShow={isError}
      />
    </div>
  );
}
