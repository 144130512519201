import "../../../../assets/css/PlantTree.css";
export default function BackgroundGame({
  children,
  bg_game,
  title,
}: {
  children: React.ReactNode;
  bg_game: string;
  title: string;
}) {
  return (
    <div className="bg-joingam relative top-0">
      <div className="relative">
        <img src={bg_game} />
        <div className="absolute top-[5%] w-full flex justify-center">
          <img src={title} className="w-10/12" />
        </div>
      </div>
      {children}
    </div>
  );
}
