import { useEffect } from "react";

export default function PopupOverlay({
  isShow,
  children,
}: {
  children: React.ReactNode;
  isShow: Boolean;
}) {
  useEffect(() => {
    if (isShow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isShow]);
  return (
    <div id="overlay">
      <div
        className="fixed z-40 overflow-y-auto top-0 w-full left-0 show"
        id="modal"
      >
        {isShow ? (
          <div className="flex items-center justify-center min-height-100vh px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-900 opacity-80" />
              <div className="containerNotify__backgrounds-physical">
                <div className="">
                  <div className="absolute z-50 content-physical-popup w-full">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
