import PopupLogin from "../../popup/PopupLogin";
import CLOSE from "../../assets/image/Home/cancel-popup.png";

export default function PopupTerm({
  isShowPopup,
  setShowPopup,
}: {
  isShowPopup: boolean;
  setShowPopup: any;
}) {
  const handleClose = () => {
    setShowPopup(false);
  };
  return (
    <PopupLogin isShow={isShowPopup}>
      <div className="py-5 px-5 text-left relative font-regular-so">
        <div className="uppercase text-[#2D4E68] text-center text-[28px] font-bold-so">
          Thông báo
        </div>
        <div className="text-[13px]">
          Bằng việc nhấn vào nút “Tôi đã đọc Điều khoản sử dụng và đồng ý với
          nội dung này”, khách hàng hiểu và đồng ý cho Công ty TNHH Nước Giải
          Khát Suntory PepsiCo Việt Nam được thu thập, xử lí các thông tin mà
          khách hàng đã cung cấp (bao gồm nhưng không giới hạn dữ liệu cá nhân
          …) cho mục đích thực hiện chương trình khuyến mại và chăm sóc khách
          hàng theo Chính Sách Quyền Riêng Tư của Công ty TNHH Nước Giải Khát
          Suntory PepsiCo Việt Nam đăng tải tại:&nbsp;
          <span className="text-[#5BC2DC]">
            <a
              href="https://www.suntorypepsico.vn/Page/chinh-sach-bao-mat"
              target="_blank"
            >
              https://www.suntorypepsico.vn/Page/chinh-sach-bao-mat
            </a>
          </span>{" "}
          và các nghĩa vụ khác theo quy định của pháp luật liên quan tới chương
          trình khuyến mại này (lưu trữ, báo cáo, phục vụ hoạt động kế toán,
          kiểm toán, thanh tra, hậu kiểm theo quy định của pháp luật).
        </div>
      </div>
      <div className="absolute -bottom-20 left-1/2 -translate-x-1/2">
        <img src={CLOSE} onClick={handleClose} />
      </div>
    </PopupLogin>
  );
}
