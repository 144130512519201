import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import "../../../assets/css/PlantTree.css";
import BackgroundGame from "./BackgroundGame/BackgroundGame";
import StartLoading from "./Loading/StartLoading";
import { DetailsPlantTree } from "../../../models/GameModel";
import { GameServices } from "../../../services/apiService/GameService";
import { BACK_GLOBAL } from "../../../services/localService/localService";
import BACK from "../../../assets/image/Header/back-header.png";
import TC from "./PopupGame/TC";
import Footer from "../../../components/NavbarMenu/Footer";

export default function IntroPlantTree() {
  const navigation = useNavigate();
  const [dataGame, setDataGame] = useState<DetailsPlantTree>();
  const [buttonPlay, setButtonPlay] = useState(false);
  const [isTc, setTc] = useState(false);
  const [contentDetails, setContentDetails] = useState("");

  const { id } = useParams();
  const handleNextLoading = () => {
    localStorage.setItem(BACK_GLOBAL, `plant-tree/${id}`);
    setButtonPlay(true);
    setTimeout(() => {
      navigation(`/plant-tree/${id}`);
    }, 3000);
  };
  useEffect(() => {
    GameServices.GetGamePlantTree(id as string)
      .then((res: any) => {
        setDataGame(res?.game_data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    GameServices.GetGamePlantTree(id as string)
      .then((res: any) => {
        localStorage.setItem("DATA_LEADER_BOARD", JSON.stringify(res));
        setDataGame(res?.game_data);
        setContentDetails(res?.content_details);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleBack = () => {
    navigation(`/`);
  };
  const handleOpenTC = () => {
    setTc(!isTc);
  };
  return (
    <BackgroundGame
      bg_game={dataGame?.loading_screen_url as string}
      title={dataGame?.title_image_url as string}
    >
      <button onClick={handleBack} className="absolute top-8 left-5">
        <img src={BACK} className="w-8" />
      </button>
      <button onClick={handleOpenTC} className="absolute top-4 right-3">
        <div className="relative top-0 w-20">
          <img src={dataGame?.frame_icon_url} />
          <div className="absolute w-full top-0">
            <img
              src={dataGame?.tnc_icon_url}
              className="w-8/12 top-0 relative left-1/2 -translate-x-1/2"
            />
          </div>
          <div className="absolute bottom-[12px] text-white text-[8px] font-regular-so leading-[8px] left-1/2 -translate-x-1/2 w-full">
            Thể lệ <br />
            chương trình
          </div>
        </div>
      </button>
      <div className="absolute w-full text-center top-[63%] -translate-y-1/2">
        {buttonPlay ? (
          <StartLoading width={100} title="loading ..." gift={""} />
        ) : (
          <button
            className="absolute w-full flex justify-center"
            onClick={handleNextLoading}
          >
            <img src={dataGame?.join_button_url} className="relative w-7/12" />
            <div className="absolute top-[45%] font-bold-so -translate-y-1/2 text-[20px] text-white">
              THAM GIA NGAY
            </div>
          </button>
        )}
      </div>
      <TC
        frameBow={dataGame?.bow_tie_image_url as string}
        imgTitle={dataGame?.tnc_icon_url as string}
        showTab={isTc}
        handleOpenTC={handleOpenTC}
        content={contentDetails}
      />
      <Footer />
    </BackgroundGame>
  );
}
