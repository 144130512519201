import { GameDetailsModel } from "../../models/GameModel";

export default function MainSpinWheel({
  runWheel,
  isAnnounce,
  transformBox,
  segments,
  isSpinning,
  game,
  borderRotation,
  backgroundRotaion,
  buttonRotation,
  label,
}: {
  runWheel?: () => void;
  isAnnounce: boolean;
  transformBox: string;
  segments: any;
  isSpinning: boolean;
  game: GameDetailsModel;
  borderRotation?: any;
  backgroundRotaion: any;
  buttonRotation: any;
  label?: any;
}) {
  return (
    <div
      className="absolute top-[42%] -translate-y-1/2 left-1/2 -translate-x-1/2 w-11/12"
      onClick={runWheel}
    >
      <div className="w-full relative z-30">
        {borderRotation}
        <div id="bg" className="bg">
          <div id="mainbox" className="mainbox">
            <div
              id="box"
              className={`box nenvongquay
                       ${isAnnounce ? "hidden" : "block"}
                      `}
              style={{
                transform: `${transformBox}`,
              }}
            >
              {backgroundRotaion}
              <div
                className="box1 font-regular-mon"
                style={{ color: "#113962" }}
              >
                <span className="segment span1 color-1 wheel1">
                  <i id="id_7" className="item7 segments">
                    <h6>{segments[6]?.gift_shortname}</h6>
                    <img
                      src={segments[6]?.gift_square_image_url}
                      loading="lazy"
                      alt="text"
                    />
                  </i>
                </span>
                <span className="segment span2 color-1">
                  <i id="id_3" className="item3 segments">
                    <h6>{segments[2]?.gift_shortname}</h6>
                    <img
                      src={segments[2]?.gift_square_image_url}
                      loading="lazy"
                      alt="text"
                    />
                  </i>
                </span>
                <span className="segment span3 color-1">
                  <i id="id_5" className="item5 segments">
                    <h6>{segments[4]?.gift_shortname}</h6>
                    <img
                      src={segments[4]?.gift_square_image_url}
                      loading="lazy"
                      alt="text"
                    />
                  </i>
                </span>
                <span className="segment span4 color-1">
                  <i id="id_1" className="item1 segments">
                    <h6>{segments[0]?.gift_shortname}</h6>
                    <img
                      src={segments[0]?.gift_square_image_url}
                      loading="lazy"
                      alt="text"
                    />
                  </i>
                </span>
              </div>
              <div
                className="box2 font-regular-mon"
                style={{ color: "#113962" }}
              >
                <span className="segment span1 color-2">
                  <i id="id_4" className="item4 segments">
                    <h6>{segments[3]?.gift_shortname}</h6>
                    <img
                      src={segments[3]?.gift_square_image_url}
                      loading="lazy"
                      alt="text"
                    />
                  </i>
                </span>
                <span className="segment span2 color-2">
                  <i id="id_8" className="item8 segments">
                    <h6>{segments[7]?.gift_shortname}</h6>
                    <img
                      src={segments[7]?.gift_square_image_url}
                      loading="lazy"
                      alt="text"
                    />
                  </i>
                </span>
                <span className="segment span3 color-2">
                  <i id="id_2" className="item2 segments">
                    <h6>{segments[1]?.gift_shortname}</h6>
                    <img
                      src={segments[1]?.gift_square_image_url}
                      loading="lazy"
                      alt="text"
                    />
                  </i>
                </span>
                <span className="segment span4 color-2">
                  <i id="id_6" className="item6 segments">
                    <h6>{segments[5]?.gift_shortname}</h6>
                    <img
                      src={segments[5]?.gift_square_image_url}
                      loading="lazy"
                      alt="text"
                    />
                  </i>
                </span>
              </div>
            </div>
            <button
              id="buttonRun"
              className="spin z-20"
              onClick={runWheel}
              disabled={isSpinning}
            >
              <img
                className="w-[105px]"
                src={game?.needle_image_url}
                alt="Image"
              />
            </button>
          </div>
        </div>
        <div className="absolute -bottom-28 w-full flex justify-center">
          {buttonRotation}
          {label}
        </div>
      </div>
    </div>
  );
}
