import "../../../../assets/css/Dragon.css";

export default function DragonEven({
  section,
  animation,
}: {
  section: string;
  animation: boolean;
}) {
  const { game_data } = JSON.parse(
    localStorage.getItem("DATA_LEADER_BOARD") || "{}"
  );
  return (
    <div className="absolute z-20 -top-12 left-1/2 -translate-x-1/2">
      <div
        className={` ${
          animation
            ? parseInt(section) % 2 == 0
              ? "animation-dragon-even"
              : "animation-dragon-odd"
            : ""
        } relative`}
      >
        <div
          className={`${
            parseInt(section) % 2 == 0 ? "" : "reverse_image"
          } relative z-20`}
        >
          <img
            src={game_data?.object_body_image_url}
            className="animation-bodydragon"
            id="reverse_image"
          />
        </div>

        <div
          className={`${
            parseInt(section) % 2 == 0 ? "" : "hind-legs_odd reverse_image"
          } absolute all-legs z-0`}
        >
          <img
            src={game_data?.object_behind_legs_image_url}
            className={`${animation ? "animation-hindlegs" : ""} w-14`}
          />
        </div>
        <div
          className={`${
            parseInt(section) % 2 == 0
              ? "front-legs_even"
              : "front-legs_odd reverse_image"
          } absolute all-legs z-0 `}
        >
          <img
            src={game_data?.object_front_legs_image_url}
            className={`${animation ? "animation-frontlegs" : ""} w-14`}
          />
        </div>
        <div
          className={`${
            parseInt(section) % 2 == 0 ? "head-even" : "head-odd reverse_image"
          } absolute z-30`}
        >
          <img
            src={game_data?.object_head_image_url}
            className="w-24 animation-headdragon animation-bodydragon"
          />
        </div>
      </div>
    </div>
  );
}
