import BG from "../../../assets/image/Game/LuckyMachine/bg.gif";
import "../../../assets/css/LuckyMachine.css";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GameServices } from "../../../services/apiService/GameService";
import { LUOT_QUAY } from "../../../services/localService/localService";
import { GameDetailsModel } from "../../../models/GameModel";
import BTN from "../../../assets/image/Game/LuckyMachine/btn.png";
import BTN_DISABLE from "../../../assets/image/Game/LuckyMachine/disablebtn.png";
import OverlayPopup from "../../../components/OverlayPopup/OverlayPopup";
import PopupError from "../../../components/Popup/PopupError";

export default function LuckyMachine() {
  const [count, setCount] = useState(-1);
  const [start, setStart] = useState(false);
  const [isLasted, setIsLasted] = useState(false);
  const [countRound, setCountRound] = useState(0);
  const [indexGift, setIndexGift] = useState(-1);
  const [game, setBackgroundGame] = useState<GameDetailsModel>();
  const [isStop, setTop] = useState<boolean>(false);
  const [dataCallUrbox, setDataCallUrbox] = useState<number>(0);
  const [gift, setGift] = useState<any>();
  const [isError, setIsError] = useState<boolean>(false);
  const [messError, setMessError] = useState<string>("Error");
  const [segments, setsegments] = useState<any>([]);
  const [lineId, setLineId] = useState();
  const [popUpGift, setPopupGift] = useState("no");
  let navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { campaign_id, sale_id } = useParams<string>();

  useEffect(() => {
    getCalculator(sale_id, campaign_id);
  }, []);
  const getCalculator = (
    sale_id: string | undefined,
    campaign_id: string | undefined
  ) => {
    const params = {
      sale_id: sale_id,
      campaign_id: campaign_id,
    };
    GameServices.CalculatorWheelApi(params)
      .then((res: any) => {
        if (res.remaining_draw === 0) {
          setIsError(true);
          setMessError("Bạn đã hết lượt chơi !!!");
        } else {
          setPopupGift("no");
          setDataCallUrbox(res.remaining_draw);
          setsegments(res.lucky_draw_list.list_lucky_draw[0].list_draw);
          setBackgroundGame(res.lucky_draw_list.game_data);
          let itemWin = res.lucky_draw_list.list_lucky_draw[0].list_win[0];
          setLineId(itemWin?.line_id);
          let codeWin = itemWin.gift_code;
          let segmentsTemp = res.lucky_draw_list.list_lucky_draw[0].list_draw;
          localStorage.setItem(
            "GAME_DATA_LOCAL",
            JSON.stringify(res.lucky_draw_list.game_data)
          );
          localStorage.setItem("GIFT_WHEEL", JSON.stringify(itemWin));
          localStorage.setItem(LUOT_QUAY, res.remaining_draw);
          setGift(itemWin);
          const a = segmentsTemp.findIndex((x: any) => x.gift_code === codeWin);
          console.log(a);
          setIndexGift(a);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
        console.log(err);
      });
  };
  useEffect(() => {
    if (start) {
      const timer = setTimeout(() => {
        if (count < 9 && !isLasted) {
          if (count === 8) {
            setCountRound((prevCount) => prevCount + 1);
            setCount(0);
            if (countRound === 4) {
              setIsLasted(true);
            }
          } else {
            setCount((prevCount) => prevCount + 1);
          }
        } else if (isLasted) {
          if (count < indexGift) {
            setCount((prevCount) => prevCount + 1);
          }
          if (count === 8) {
            return () => clearTimeout(timer);
          }
          // setTimeout(() => {
          //   navigation(`/receive-code/${id}`);
          // }, 2000);
        }
      }, 125);
    }
  }, [count, isLasted, countRound, start]);
  function isEqual(obj1: any, obj2: any) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }
  const handleRun = () => {
    const positions = [];
    setStart(true);
    if (!start) {
      countDraw();
      const data = JSON.parse(localStorage.getItem("WHEEL_PHANTHUONG") || "{}");
      console.log(data);
      for (let i = 0; i < segments.length; i++) {
        if (isEqual(segments[i]?.gift_code, data?.gift_code)) {
          positions.push(i);
        }
      }
      console.log(positions);
    }
  };
  const rederArrayItem = () => {
    let items = [];
    for (let i = 0; i < 9; i++) {
      // console.log(i);
      items.push(
        <div
          className="h-hull rounded-md relative w-full flex justify-center"
          style={{ backgroundColor: count === i ? "#4D8AF8" : "#F0FBFE" }}
        >
          <img
            src={segments[i]?.gift_square_image_url}
            className="absolute w-10/12 top-1/2 -translate-y-1/2"
          />
        </div>
      );
    }
    return items;
  };
  const countDraw = async () => {
    const data = {
      sale_id: sale_id,
      campaign_id: campaign_id,
      line_id: lineId,
    };

    GameServices.CountDrawApi(data)
      .then((res: any) => {
        localStorage.setItem(LUOT_QUAY, res.remaining_draw);
        if (res.remaining_draw === 0) {
          // setTimeout(() => {
          const dataParams = {
            sale_id: sale_id,
            campaign_id: campaign_id,
          };
          GameServices.SendGiftApi(dataParams)
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
          // }, 7000);
        }
        setTimeout(() => {
          setTop(true);
        }, 7000);
        localStorage.setItem("LUCKY_CODE", JSON.stringify(res));
        setDataCallUrbox(res.remaining_draw);
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
        console.log(err);
      });
  };
  const { hotline } = JSON.parse(
    localStorage.getItem("HOME_DATA_LOCAL") || "{}"
  );
  const handleClosePopup = () => {
    getCalculator(sale_id, campaign_id);
    setTop(false);
    setCount(-1);
    setCountRound(0);
    setStart(false);
    setIsLasted(false);
    setIndexGift(-1);
  };
  const handleCloseError = () => {
    navigation("/games");
    setIsError(false);
  };
  return (
    <div
      className="relative"
      style={{
        backgroundImage: `url(${game?.background_image_url})`,
        backgroundPosition: "contain",
        backgroundSize: "100% 100%",
        width: "100vw",
        minHeight: "100vh",
      }}
    >
      <div className="flex justify-center">
        <img src={game?.title_image_url} className="w-6/12 my-3" />
      </div>
      <div className="relative bg-machine">
        <div className="relative ">
          <img src={game?.machine_header_image_url} />
          <div className="absolute flex justify-between top-3 w-full px-5">
            <div>
              <img src={game?.claw_image_url} />
            </div>
          </div>
          <div className="absolute top-[6px] py-16 h-full px-8 w-full">
            <div className="grid grid-cols-3 h-full gap-2 text-center">
              {rederArrayItem()}
            </div>
          </div>
          <div className="absolute text-center bottom-3 left-1/2 -translate-x-1/2 w-6/12 bg-[#B7FDF5] rounded-full border-amount-game ">
            Bạn có {dataCallUrbox} lượt chơi
          </div>
        </div>
        <div className="relative -top-[1px]">
          <img src={game?.machine_footer_image_url} />
          <div className="absolute bottom-[48px] w-full text-center text-white">
            Hotline <a href={`tel:${hotline}`}>{hotline}</a>
          </div>
          <div className="absolute top-8 w-full text-center">
            <button
              className="pushable-luckymacine label-transxluc relative z-0"
              onClick={handleRun}
            >
              <div className="relative">
                <img src={dataCallUrbox === 0 ? BTN_DISABLE : BTN} />
                <div className="absolute uppercase text-white top-2 left-1/2 -translate-x-1/2 text-[23px] w-full font-regular-so">
                  Nhấn ngay{" "}
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <OverlayPopup isShow={isStop}>
        <div>
          <div className="relative top-0 w-full">
            <div className="absolute w-full -top-40">
              <img src={BG} className=" w-full" />
            </div>
            <div
              className=""
              style={{
                backgroundImage: `url(${game?.gift_frame_url})`,
                backgroundPosition: "contain",
                backgroundSize: "100% 100%",
                width: "93vw",
                minHeight: "70vh",
                position: "relative",
                top: "0px",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <div className="leading-7 text-[22px] pt-1 font-semibold-so text-white">
                {gift?.no_gift ? (
                  <>
                    CHÚC BẠN MAY MẮN
                    <br />
                    LẦN SAU
                  </>
                ) : (
                  <>
                    CHÚC MỪNG BẠN
                    <br /> ĐÃ TRÚNG
                  </>
                )}
              </div>
              <div className="mt-[6%]">
                <div>{gift?.no_gift ? "" : gift?.gift_name}</div>
                <div className="w-full flex justify-center">
                  <img src={gift?.gift_reward_image_url} className="w-7/12" />
                </div>
                <div className="px-3 text-[14px] mt-3">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: gift?.instructions_redeeming_rewards,
                    }}
                    className="font-regular-so text-[#4F4F4F]"
                  ></div>
                </div>
                <div className="relative top-0">
                  <button
                    className="pushable-luckymachine label-transxluckymachine relative top-2 z-20"
                    onClick={
                      dataCallUrbox === 0
                        ? () => {
                            navigation("/list-gift");
                          }
                        : () => handleClosePopup()
                    }
                  >
                    <span
                      id=""
                      className="front-luckymachine relative z-10"
                    ></span>
                  </button>

                  <div
                    onClick={
                      dataCallUrbox === 0
                        ? () => {
                            navigation("/list-gift");
                          }
                        : () => handleClosePopup()
                    }
                    className="text-[18px] font-regular-so z-30 text-white absolute left-1/2 -translate-y-1/2 top-[45%] w-full -translate-x-1/2"
                  >
                    {dataCallUrbox > 0 ? (
                      <>Bạn còn {dataCallUrbox} lượt chơi</>
                    ) : (
                      <>Quà của tôi</>
                    )}
                  </div>
                </div>
              </div>
              <span className="text-[#2D4E68] text-[13px] font-regular-so relative top-1">
                Hotline <a href={`tel:${hotline}`}>{hotline}</a>
              </span>{" "}
            </div>
          </div>
        </div>
      </OverlayPopup>
      <PopupError
        labelBtn={"Đồng ý"}
        messError={messError}
        handleClose={handleCloseError}
        isShow={isError}
      />
    </div>
  );
}
