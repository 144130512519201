import { useNavigate } from "react-router-dom";
import BG from "../../../assets/image/Popup/header.png";
import PLAY from "../../../assets/image/Popup/play.png";
import ANIMATE from "../../../assets/image/Popup/animate-bg.png";
import COIN from "../../../assets/image/coin.png";
import CODE from "../../../assets/image/Popup/code.png";
import { ListReceipt, LuckyDrawList } from "../../../models/GameModel";
import { setAuthorization } from "../../../services/apiService/configURL";
import { homeServices } from "../../../services/apiService/homeService";
import {
  gameLocal,
  userLocal,
} from "../../../services/localService/localService";
import { Fragment } from "react/jsx-runtime";
import ContactPhoneZalo from "../../../components/ContactPhoneZalo/ContactPhoneZalo";
import { useEffect, useState } from "react";
import LoadingButton from "../../../components/Loading/LoadingButton";
import BTN_CONTINUE from "../../../assets/image/Popup/btn-continue.png";

export default function PopupConfirm({
  isShow,
  listReceipt,
  setConfirm,
}: {
  isShow: boolean;
  listReceipt: ListReceipt;
  setConfirm: any;
}) {
  const [listLeaderBoard, setListLeaderBoard] = useState<any>();
  useEffect(() => {
    if (isShow) {
      localStorage.setItem("STATUS_CONFIRM", "TRUE");
    }
    let gameLeaderBoard = listReceipt?.rewards?.filter(
      (x: LuckyDrawList) => x.game_id < 0
    );

    setListLeaderBoard(gameLeaderBoard);
  }, [isShow]);
  let { token } = userLocal.get();
  setAuthorization(token);
  document.body.style.overflow = "unset";
  const navigation = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const handleConfirmGift = () => {
    setLoading(true);
    document.body.style.overflow = "unset";

    const params = {
      sale_id: listReceipt?.sale_id,
    };
    const filterPeriod = listReceipt?.rewards?.filter(
      (x: LuckyDrawList) => x.campaign_type === "periodic"
    );
    console.log(filterPeriod.length);
    homeServices
      .customerConfirmApi(params)
      .then((res) => {
        if (listReceipt.show_leader_board) {
          navigation(
            `/list-leader-board/${listReceipt.leader_board_campaign_id}`
          );
        } else {
          if (filterPeriod.length === 0) {
            if (listReceipt.rewards.length === 1) {
              if (listReceipt.rewards[0]?.game_id === -1) {
                navigation(`/list-gift/3`);
              } else {
                gameLocal.set(listReceipt.rewards[0].game_data);
                const a = listReceipt.rewards[0].game_data;
                const campaign_id = listReceipt.rewards[0].campaign_id;
                const sale_id = listReceipt.sale_id;
                navigation(`/${a.game_type}/${campaign_id}/${sale_id}`);
                localStorage.setItem("LOCATION_STATUS_GAME", "TRUE");
              }
            } else {
              let gameFilter = listReceipt?.rewards?.filter(
                (x: LuckyDrawList) => x.game_id > 0
              );
              if (listReceipt.rewards.length === 0) {
                navigation("/exchange-point");
              } else {
                if (gameFilter?.length > 1) {
                  navigation("/games");
                } else {
                  const checkGame = gameFilter[0].game_data;
                  const checkCampaign = gameFilter[0].campaign_id;
                  const sale_id = listReceipt.sale_id;
                  navigation(
                    `/${checkGame.game_type}/${checkCampaign}/${sale_id}`
                  );
                }
              }
            }
          } else {
            navigation("/dragon_snake?id=2");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        localStorage.removeItem("SALE_ID");
        setConfirm(false);
        setLoading(false);
      });
  };
  const onRedirect = (game_id: string, campaign_id: number) => {
    const params = {
      sale_id: listReceipt?.sale_id,
    };
    homeServices
      .customerConfirmApi(params)
      .then((res) => {
        navigation(`/${game_id}/${campaign_id}/${listReceipt?.sale_id}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const { hotline, zalo_link } = JSON.parse(
    localStorage.getItem("HOME_DATA_LOCAL") || "{}"
  );
  return (
    <div className="relative w-full">
      {isShow ? (
        <div className="w-full min-h-full background-result">
          <div className="w-full">
            <img src={BG} className="w-full" />
            <div className="absolute top-40 z-0">
              <img src={ANIMATE} className="animate-bgresult rounded-full" />
            </div>
            <div className="relative w-full popupGift__background">
              <div className="min-h-[450px]">
                {listReceipt?.rewards?.map((res, index) => {
                  return (
                    <div className="flex flex-col w-full justify-center items-center">
                      {res?.lucky_draw_count > 0 ? (
                        <>
                          <div className="w-52 h-52 flex flex-col items-center justify-center text-[#ffffff]">
                            <div className="flex justify-center items-center font-bold-so text-[70px]">
                              +{res?.lucky_draw_count}
                              <div className="ml-1 flex items-center h-full">
                                <img src={PLAY} className="w-10 mt-4" />
                              </div>
                            </div>
                            <div className="-mt-3 text-[20px] text-center font-semibold-so leading-5">
                              lượt xác định trúng thưởng
                            </div>
                          </div>
                          <div className="text-[#ffffff] font-semibold-so text-[20px] -mt-7 text-center">
                            <div className="text-[#00E0FC] uppercase text-[12px]">
                              Chương trình
                            </div>
                            <div className="text-[#ffffff] px-5 text-[18px] font-medium-so">
                              {res?.campaign_name}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  );
                })}

                {listReceipt?.rewards?.map((res, index) => (
                  <Fragment>
                    {res.gifts?.length > 0 && (
                      <div key={index} className="pb-10">
                        {res.gifts?.map((item: any, indexItem) => (
                          <>
                            <div className="relative top-5 left-1/2 -translate-x-1/2 w-80 h-56 flex flex-col justify-center items-center">
                              {item.quantity > 0 ? (
                                <>
                                  <div className=" flex flex-col items-center justify-center">
                                    <div className="flex justify-center items-center text-[#ffffff] font-bold-so text-[70px]">
                                      +{item.quantity}
                                    </div>
                                    <div className="w-40 ml-1 flex items-center h-full text-[#ffffff]">
                                      <img
                                        src={item?.gift_square_image_url}
                                        className="w-40 -mt-2"
                                      />
                                    </div>
                                    <div className="text-[#ffffff] mt-0 text-center max-w-[350px] text-[20px] leading-6 font-semibold-so">
                                      {item?.gift_name}
                                    </div>
                                  </div>

                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item?.instructions_redeeming_rewards,
                                    }}
                                    className="font-light-so text-[14px] mt-0 text-center text-[#ffffff]"
                                  ></div>
                                  <div className=" font-semibold-so text-[20px] mt-2 text-center">
                                    <div className="text-[#00E0FC] uppercase text-[12px]">
                                      Chương trình
                                    </div>
                                    <div className="text-[#ffffff] text-[20px] font-semibold-so">
                                      {res?.campaign_name}
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </>
                        ))}
                      </div>
                    )}
                  </Fragment>
                ))}
                {listReceipt?.rewards?.map((res, index: number) => (
                  <Fragment>
                    {res.point !== 0 && (
                      <>
                        <div className="flex mt-2 justify-center items-center text-[#4E9ABB] font-bold-so text-[80px]">
                          +{res.point}
                          <div className="ml-1">
                            <img src={COIN} className=" mt-4" />
                          </div>
                        </div>
                        <div className="text-white text-center -mt-5 font-semibold-so text-[20px]">
                          Pepcoin
                          <div className="text-[#4E9ABB] uppercase text-[12px]">
                            Chương trình
                          </div>
                          <div className="text-[#ffffff] text-[20px]">
                            {res?.campaign_name}
                          </div>
                        </div>
                      </>
                    )}
                  </Fragment>
                ))}
                {listReceipt?.rewards?.map((res, index: number) => (
                  <Fragment>
                    {res.lucky_code_count > 0 && (
                      <>
                        <div className="flex -mt-5 flex-col w-full justify-center items-center">
                          <div className="border-img-point w-52 h-52 flex flex-col items-center justify-center">
                            <div className="flex justify-center items-center text-[#4E9ABB] font-bold-so text-[70px]">
                              +{res?.lucky_code_count}
                              <div className="ml-1 flex items-center h-full">
                                <img
                                  src={CODE}
                                  className="w-10 mt-4 rounded-full"
                                />
                              </div>
                            </div>
                            <div className="-mt-5 text-center font-semibold-so text-[20px]">
                              Mã dự thưởng
                            </div>
                          </div>
                          <div className=" font-semibold-so text-[20px] -mt-10 text-center">
                            <div className="text-[#243F94] uppercase text-[12px]">
                              Chương trình
                            </div>
                            <div className="text-[#333333] text-[20px]">
                              {res?.campaign_name}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </Fragment>
                ))}
                {listLeaderBoard?.length > 0 && (
                  <>
                    {listLeaderBoard[0].lucky_program_content_details && (
                      <div className="relative top-5 w-10/12 left-1/2 -translate-x-1/2 flex justify-center rounded-xl border-code-confirm">
                        <div className="relative text-center py-3">
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                listLeaderBoard[0]
                                  .lucky_program_content_details,
                            }}
                            className="text-[14px]"
                          ></div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {listReceipt?.base_point !== 0 && (
                  <>
                    <div className="flex mt-5 flex-col w-full justify-center items-center">
                      <div className="w-52 h-52 flex flex-col items-center justify-center ">
                        {(listReceipt?.rewards?.length as number) !== 0 && (
                          <div className="top-1 font-light-italic-so relative text-white">
                            Và tích lũy thêm
                          </div>
                        )}{" "}
                        <div className="flex justify-center items-center text-white font-bold-so text-[70px]">
                          +{listReceipt.base_point}
                          <div className="ml-1 flex items-center h-full">
                            <img
                              src={COIN}
                              className="w-10 mt-4 rounded-full"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-full font-semibold-so text-[20px] -mt-10 text-center">
                        <div className="text-[#00E0FC] uppercase text-[12px]">
                          Pepcoin
                        </div>
                        <div className="relative uppercase text-[#ffffff] text-[20px] font-semibold-so w-full">
                          Tích Pepcoin Săn Ngay Quà Hot
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="h-40 w-full"></div>
            <div className="fixed w-full bottom-0 h-24">
              <div className="flex justify-center px-5 w-full z-30">
                {isLoading ? (
                  <button>
                    <LoadingButton />
                  </button>
                ) : (
                  <button onClick={handleConfirmGift} className="">
                    <img src={BTN_CONTINUE} />
                  </button>
                )}
              </div>
              <div className="text-white font-light-so text-[12px] w-full text-center mt-3 z-20">
                {" "}
                Gọi điện cho Hotline{" "}
                <a
                  href={`tel:${hotline}`}
                  className="text-white font-medium-so"
                >
                  {" "}
                  {hotline}
                </a>{" "}
                để được hỗ trợ
              </div>
            </div>
            <ContactPhoneZalo />
          </div>
        </div>
      ) : null}
    </div>
  );
}
