import BUTTON from "../../../assets/image/Game/FlipCard/button.png";
import FLIP from "../../../assets/image/Game/FlipCard/flip.png";
import "../../../assets/css/FlipCard.css";
import PopupOverlay from "../../../components/BackgroundPopup/PopupOverLay";
import BackgroundGame from "../../../components/BackgroundPopup/BackgroundGame";
import { GameServices } from "../../../services/apiService/GameService";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Loading from "../../../components/Loading/Loading";
import {
  LUOT_QUAY,
  userLocal,
} from "../../../services/localService/localService";
import { setAuthorization } from "../../../services/apiService/configURL";
import PopupError from "../../../components/Popup/PopupError";

export default function FlipCard() {
  let { token } = userLocal.get();
  const { campaign_id, sale_id } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [messError, setMessError] = useState<string>("Error");
  const [popUpGift, setPopupGift] = useState("no");
  const [spinRemain, setSpinRemain] = useState<number>(0);
  const [lineId, setLineId] = useState();
  const [gift, setGift] = useState<any>();
  const [game, setGame] = useState<any>();
  const [isOpenPopup, setIsPopup] = useState<boolean>(false);
  const navigation = useNavigate();
  setAuthorization(token);
  useEffect(() => {
    setLoading(true);
    getCalculator(sale_id, campaign_id);
  }, []);
  useEffect(() => {
    getCalculator(sale_id, campaign_id);
  }, [popUpGift === "change"]);
  const getCalculator = (
    sale_id: string | undefined,
    campaign_id: string | undefined
  ) => {
    const params = {
      sale_id: sale_id,
      campaign_id: campaign_id,
    };
    GameServices.CalculatorWheelApi(params)
      .then((res: any) => {
        if (res.remaining_draw === 0) {
          setIsError(true);
          setMessError("Bạn đã hết lượt chơi !!!");
        } else {
          setLoading(false);
          setGame(res.lucky_draw_list.game_data);
          setPopupGift("no");
          setSpinRemain(res.remaining_draw);
          const countGiftWin = res.total_draw - res.remaining_draw;
          const giftWin = res.lucky_draw_list.list_lucky_draw[0].list_win[0];
          setGift(giftWin);
          setLineId(giftWin?.line_id);
          localStorage.setItem(
            "LIST_LUCKY_DRAW",
            JSON.stringify(res.lucky_draw_list.list_lucky_draw)
          );
          localStorage.setItem("GIFT_WHEEL", JSON.stringify(giftWin));
          localStorage.setItem(LUOT_QUAY, res.remaining_draw);
        }
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
      });
  };
  const handleCloseError = () => {
    navigation("/games");
  };
  const handleClick = () => {
    setIsPopup(true);
    runWheel();
  };
  const runWheel = () => {
    const data = {
      sale_id: sale_id,
      campaign_id: campaign_id,
      line_id: lineId,
    };
    GameServices.CountDrawApi(data)
      .then((res: any) => {
        localStorage.setItem(LUOT_QUAY, res.remaining_draw);
        setSpinRemain(res.remaining_draw);
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
        console.error(err);
      });
  };
  const handleClose = () => {
    const luot_quay = localStorage.getItem(LUOT_QUAY);
    if (luot_quay === "0") {
      navigation("/list-gift");
    } else {
      setPopupGift("change");
      setIsPopup(false);
    }
  };
  return (
    <div>
      {isLoading ? <Loading /> : null}
      <BackgroundGame img={game?.background_image_url}>
        <div className="absolute top-5 w-full px-5">
          <img src={game?.title_image_url} loading="lazy" className="w-full" />
        </div>
        <div className="absolute top-[180px] w-full px-5">
          <div className="grid grid-cols-2 gap-4 w-full">
            <div
              className="col-span-1 w-full flex justify-center"
              onClick={handleClick}
            >
              <img
                src={game?.card_image_url}
                loading="lazy"
                className="w-full"
              />
            </div>
            <div
              className="col-span-1 w-full flex justify-center"
              onClick={handleClick}
            >
              <img
                src={game?.card_image_url}
                loading="lazy"
                className="w-full"
              />
            </div>
            <div
              className="col-span-1 w-full flex justify-center"
              onClick={handleClick}
            >
              <img
                src={game?.card_image_url}
                loading="lazy"
                className="w-full"
              />
            </div>
            <div
              className="col-span-1 w-full flex justify-center"
              onClick={handleClick}
            >
              <img
                src={game?.card_image_url}
                loading="lazy"
                className="w-full"
              />
            </div>
          </div>
          <div className="text-white top-20 relative left-1/2 -translate-x-1/2 w-full">
            <div className="flex justify-center relative">
              <img src={BUTTON} />
              <div className="absolute top-1/2 -translate-y-1/2 text-[#2D4E68]">
                {spinRemain !== 0
                  ? `Bạn có ${spinRemain} lượt chơi`
                  : "Hết lượt chơi"}
              </div>
            </div>
          </div>
        </div>
      </BackgroundGame>

      <PopupOverlay isShow={isOpenPopup}>
        <div className="relative z-50">
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <div className="w-full absolute -top-1/2">
                  <img
                    src={FLIP}
                    alt="Avatar"
                    className="w-full relative"
                    loading="lazy"
                  />
                  <div className="w-full absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 gift-image text-white">
                    <div className="px-16 leading-8 font-semibold-so text-[24px] w-full">
                      CHÚC MỪNG BẠN ĐÃ TRÚNG
                      <div className="flex justify-center">
                        <img
                          src={gift?.gift_reward_image_url}
                          className="w-7/12 mt-3"
                        />
                      </div>
                    </div>
                    <div className="mt-3 font-semibold-so text-[20px]">
                      {gift?.gift_name}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: gift?.conditions_receiving_prize,
                      }}
                      className="mt-1 px-14"
                    ></div>
                    <div className="relative text-white mt-2">
                      <button
                        onClick={handleClose}
                        className="w-36 py-1 leading-5 rounded-xl bg-[#1D86FA] px-2"
                      >
                        Nhập thông tin nhận hàng
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flip-card-back">
                <div className="w-full absolute -top-1/2">
                  <img
                    src={game?.card_image_url}
                    alt="Avatar"
                    className="w-full"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </PopupOverlay>
      <PopupError
        labelBtn={"Đồng ý"}
        messError={messError}
        handleClose={handleCloseError}
        isShow={isError}
      />
    </div>
  );
}
