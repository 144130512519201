import PopupPlantTree from "../../../../components/Popup/GameLuckyMachine/PopupPlantTree";

export default function TC({
  showTab,
  handleOpenTC,
  content,
  imgTitle,
  frameBow,
}: {
  showTab: Boolean;
  handleOpenTC: () => void;
  content: string;
  imgTitle: string;
  frameBow: string;
}) {
  return (
    <PopupPlantTree
      frameBow={frameBow}
      title={"THỂ LỆ CHƯƠNG TRÌNH"}
      imgTitle={imgTitle}
      isShow={showTab}
      handleOpenBoard={handleOpenTC}
    >
      <div className="mt-8 px-5">
        <div
          className=" font-light-so text-[12px] text-left point-ul line-table"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        ></div>
      </div>
    </PopupPlantTree>
  );
}
