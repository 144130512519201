import PopupPlantTree from "../../../../components/Popup/GameLuckyMachine/PopupPlantTree";
import { ListQuest } from "../../../../models/GameModel";
import Item from "./ItemQuest/Item";

export default function Quest({
  showTab,
  handleOpenBoard,
  lstQuest,
  imgTitle,
  frameBow,
}: {
  showTab: Boolean;
  handleOpenBoard: () => void;
  lstQuest: any;
  imgTitle: string;
  frameBow: string;
}) {
  return (
    <PopupPlantTree
      frameBow={frameBow}
      title={"NHIỆM VỤ LẤY NƯỚC"}
      imgTitle={imgTitle}
      isShow={showTab}
      handleOpenBoard={handleOpenBoard}
    >
      <div className="relative top-8 px-2 mb-5">
        {lstQuest?.map((res: ListQuest) => (
          <Item res={res} />
        ))}
      </div>
    </PopupPlantTree>
  );
}
