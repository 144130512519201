import { useState } from "react";
import Register1Component from "./Register1Component";
import Register2Component from "./Register2Component";
import { LoginServices } from "../../services/apiService/LoginService";
import { useNavigate } from "react-router-dom";
import PopupError from "../../components/Popup/PopupError";

const title = "CẬP NHẬT TÀI KHOẢN";
export default function Register() {
  const [currentComponent, setCurrentComponent] = useState("Component1");
  const data_user = JSON.parse(localStorage.getItem("DATA_USER") || "{}");
  const [data, setData] = useState<any>(data_user);
  const phone = localStorage.getItem("PHONE");
  const goToNext = (e: any) => {
    const params = Object.assign({}, data, e);
    setData(params);
    setCurrentComponent("Component2");
  };

  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigation = useNavigate();
  const goToPrevious = (e: any) => {
    const params = Object.assign(data, e);
    setIsLoading(true);
    LoginServices.Register(params)
      .then((res: any) => {
        navigation(`/validate-password`);
      })
      .catch((err) => {
        setIsError(true);
        setMessage(err);
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleBack = () => {
    setCurrentComponent("Component1");
  };
  const handleBackHeader = () => {
    navigation("/login");
  };
  const handleClose = () => {
    setIsError(false);
  };
  const handleNext = (e: any) => {
    const dataFormat = {
      phone: phone,
      name: e,
      email: "",
      gender: "",
      birthday: false,
      street: "",
      city_id: 0,
      district_id: 0,
      ward_id: 0,
    };
    const params = Object.assign(dataFormat, data);
    setIsLoading(true);
    LoginServices.Register(params)
      .then((res: any) => {
        navigation(`/validate-password`);
      })
      .catch((err) => {
        setIsError(true);
        setMessage(err);
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div className="">
      {currentComponent === "Component1" && (
        <Register2Component
          handleBack={handleBackHeader}
          goToNext={(e: any) => goToNext(e)}
          isLoading={isLoading}
          dataRegister={data}
        />
      )}
      {currentComponent === "Component2" && (
        <Register1Component
          handleNext={(e: any) => handleNext(e)}
          required={true}
          dataRegister={data}
          goToPrevious={(e: any) => goToPrevious(e)}
          title={title}
          labelButton="Đăng ký"
          consent={true}
          handleBack={handleBack}
          updateAfter={true}
          isLoading={isLoading}
        />
      )}
      <PopupError
        isShow={isError}
        messError={message}
        labelBtn="Đóng"
        handleClose={handleClose}
      />
    </div>
  );
}
