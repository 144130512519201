import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { homeServices } from "../../../services/apiService/homeService";
import HEADER_DETAILS from "../../../assets/image/Header/header-details.png";
import BACK from "../../../assets/image/Header/back-header.png";
import { BrandNews, NewsDetails } from "../../../models/HomePage";

export default function DetailsNews() {
  const navigation = useNavigate();
  const { id } = useParams();
  const [newsDetails, setNewsDetails] = useState<NewsDetails>();
  useEffect(() => {
    getDetail(id as string);
  }, []);
  const getDetail = (id: string) => {
    homeServices
      .GetNewsDetails(id as string)
      .then((res: any) => {
        window.scrollTo(0, 0);
        setNewsDetails(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleBack = () => {
    navigation("/");
  };
  const handleNewsDetails = (e: string) => {
    window.scrollTo(0, 0);
    getDetail(e);
  };
  return (
    <div>
      <div>
        <div className="">
          <div className="w-full h-20 bg-[#5BC2DC]">
            <img src={HEADER_DETAILS} />
          </div>
          <div className="containerNotify__background bg-[#fff] absolute rounded-[20px_10px_0_0] top-10 h-[96%] w-full z-10">
            <ul className="containerNotify__background-list box-border z-30">
              <div className="relative">
                <div className="relative text-[20px] w-full text-center text-[#2D4E68] font-semibold-so">
                  <img
                    src={newsDetails?.mini_banner_image_url}
                    className="w-full rounded-[20px]"
                  />
                  <div className="absolute top-3 left-4">
                    <img
                      src={BACK}
                      onClick={handleBack}
                      className="w-10 relative"
                    />
                  </div>
                </div>
                <div className="px-5">
                  <div className="text-[20px] font-semibold-so mt-4">
                    {newsDetails?.name}
                  </div>
                  {newsDetails && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: newsDetails?.content,
                      }}
                      className="text-[14px] font-light-so mt-3"
                    ></div>
                  )}
                  <div className="text-[20px] font-semibold-so">
                    Tin liên quan
                  </div>
                  {newsDetails &&
                    newsDetails.related_news?.map(
                      (res: BrandNews, index: number) => (
                        <div
                          onClick={() => handleNewsDetails(res?.id)}
                          className="p-2 shadow-item rounded-2xl my-3"
                          key={index}
                        >
                          <div className="grid grid-cols-12">
                            <div className="col-span-3">
                              <img
                                src={res?.mini_banner_image_url}
                                className="rounded-xl"
                              />
                            </div>
                            <div className="col-span-9 px-2 flex flex-col justify-between">
                              <div className="uppercase font-semibold-so leading-[20px] text-reponsive">
                                {res.name}
                              </div>
                              <div className="font-light-so text-[#8A8A8A] text-[12px] leading-[16px] text-reponsive">
                                {res.short_content}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
