import { Fragment } from "react/jsx-runtime";
import { useNavigate, useParams } from "react-router-dom";
import PHONE from "../../assets/image/Popup/phone.png";
import {
  GiftsLeaderBoard,
  LeaderBoard,
  RacesLeaderBoard,
} from "../../models/LeaderBoard";

export default function WeekLeaderBoard({
  listBoard,
  typeGift,
  isDisableButton,
  type,
  title,
}: {
  listBoard: LeaderBoard;
  typeGift: string;
  isDisableButton: Boolean;
  type?: string;
  title?: string;
}) {
  const data_bg = JSON.parse(localStorage.getItem("DATA_LEADER_BOARD") || "{}");
  const { id } = useParams();
  const navigation = useNavigate();
  const handleRedirectToListPrize = (race_id: number, status: string) => {
    if (type) {
      localStorage.setItem("RACE_ID", race_id.toString());
      navigation(`/plant-tree/${id}/plant-tree`);
    } else {
      if (status === "ended") {
        navigation(`/list-leader-board/${id}/${race_id}/${typeGift}`);
      } else if (status === "in_progress") {
        navigation(`/prize-game/${id}`);
      }
    }
  };
  const { hotline } = JSON.parse(
    localStorage.getItem("HOME_DATA_LOCAL") || "{}"
  );
  const handlePrize = () => {
    console.log(1);

    navigation(`/list-leader-board/${id}`);
  };
  console.log(listBoard);
  const handleListGift = () => {
    navigation("/list-gift/3");
  };
  let listBoardFilter = [];
  listBoardFilter = listBoard?.gifts?.sort(
    (a: GiftsLeaderBoard, b: GiftsLeaderBoard) => {
      if (a.plan_type === "special") return -1;
      if (b.plan_type === "special") return 1;
      return 0;
    }
  );
  console.log(listBoardFilter);
  return (
    <Fragment>
      <div>
        {listBoardFilter?.map((res: GiftsLeaderBoard) => (
          <div className="relative top-0">
            <div className="relative w-full grid grid-cols-12">
              <div className="col-span-4">
                <div className="relative top-0">
                  <img
                    src={res.gift_square_image_url}
                    className="relative top-0 z-10 w-full "
                  />
                  <div className="absolute bottom-2 z-20 w-full">
                    <div className="w-full relative flex justify-center">
                      <img
                        src={res.gift_bow_tie_image_url}
                        className="w-10/12 relative z-30"
                      />
                    </div>
                    <div className="absolute top-1/2 -translate-y-1/2 z-40 font-bold-so text-[12px] w-full flex justify-center">
                      {res.plan_type === "week"
                        ? "GIẢI TUẦN"
                        : res.plan_type === "month"
                        ? "GIẢI THÁNG"
                        : res.plan_type === "top"
                        ? title
                        : "GIẢI ĐẶC BIỆT"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-8">
                <div className="relative top-0 left-1/2 -translate-x-1/2 w-full h-full z-10">
                  <div className="w-11/12 col-span-9 flex flex-col items-center justify-center h-full">
                    <div className="text-center font-semibold-so leading-5 text-[#4E9ABB] text-[14px]">
                      {res.gift_name}
                    </div>
                    <div className="text-[12px] text-[#5D6A85] -mt-[3px]">
                      Còn lại &nbsp;
                      <span className="font-semibold-so text-[16px] text-[#FF5990]">
                        {res.gift_remaining}
                      </span>{" "}
                      &nbsp; / {res.gift_planning} giải thưởng
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: res?.gift_extra_content,
                      }}
                      className="font-regular-so text-[11px] text-[#818BA0] text-center leading-3"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute right-0 top-1/2 -translate-y-1/2 z-0">
              <div className="relative top-0 w-full">
                <img src={res.gift_frame_image_url} className=" w-full" />
              </div>
            </div>
          </div>
        ))}
        <div className="grid grid-cols-2 w-full gap-4 text-white mt-5">
          {listBoard?.races?.map((res: RacesLeaderBoard) => (
            <div
              className="col-span-1 w-full relative text-center"
              onClick={() =>
                handleRedirectToListPrize(
                  res.race_id as number,
                  res.race_state as string
                )
              }
            >
              <div className="w-full relative">
                <img
                  src={
                    res.race_state === "ended" ||
                    res.race_state === "in_progress" ||
                    res.race_state === "updating_result"
                      ? data_bg.race_item_image_url
                      : data_bg.race_item_mute_image_url
                  }
                  className="w-full"
                />
              </div>
              <div className="absolute top-[11%] left-1/2 -translate-x-1/2 ">
                {res.race_name}
              </div>
              <div className="absolute top-[28%] w-full">
                <div className="text-[12px] font-light-so">
                  {res.race_duration}{" "}
                </div>
              </div>
              <div className="absolute top-1/2 -translate-y-1/2 font-semibold-so w-full text-[20px] text-[#B5E0EE]">
                {res.race_status}
                {res.race_state === "updating_result" ? "..." : null}
              </div>
              <div className="absolute bottom-[-1px] font-regular-so left-1/2 -translate-x-1/2 w-full px-6">
                {res.race_state === "ended" ? (
                  <img src={data_bg.race_result_image_url} />
                ) : res.race_state === "in_progress" ? (
                  <img src={data_bg.race_join_image_url} />
                ) : res.race_state === "updating_result" ? (
                  <img src={data_bg.race_result_mute_image_url} />
                ) : res.race_state === "incoming" ? (
                  <img src={data_bg.race_join_mute_image_url} />
                ) : null}
              </div>
            </div>
          ))}
        </div>
        <div className="h-24"></div>
      </div>

      <div className="flex justify-center flex-col pb-8 fixed left-0 bottom-0 w-full px-6 fade-background">
        {!isDisableButton && (
          <div className="relative z-10 grid grid-cols-2 gap-2 text-white pt-3">
            <div className="col-span-1">
              <button
                className="w-full rounded-xl leading-5 py-[18px] shadow-item bg-[#81BEB4]"
                onClick={handlePrize}
              >
                Bảng xếp hạng
              </button>
            </div>
            <div className="col-span-1">
              <button
                className="w-full rounded-xl leading-5 py-[18px] shadow-item bg-[#2D4E68]"
                onClick={handleListGift}
              >
                Mã dự thưởng
              </button>
            </div>
          </div>
        )}
        <div className="absolute z-0 bottom-0 w-full left-0 flex justify-center">
          <div className="flex items-center mt-20">
            <div>
              <img src={PHONE} />
            </div>
            <div className="text-[#2D4E68] font-light-so ml-2">
              Liên hệ Hotline{" "}
              <span className="font-semibold-so">
                <a href={`tel:${hotline}`}>{hotline}</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
