export default function Photo({
  image,
  onClickDeleteImg,
  pushImageToGCS,
}: {
  image: string;
  onClickDeleteImg: (e: any) => void;
  pushImageToGCS: () => void;
}) {
  return (
    <div>
      <div>
        <div>
          <img
            src={image}
            className="w-full object-cover min-h-screen relative top-0"
            alt="upload view"
          />
        </div>
        <div className="grid grid-cols-2 w-full gap-[25px] p-4 fixed bottom-10">
          <button
            onClick={(e: any) => onClickDeleteImg(e)}
            className="bg-[#81BEB4] col-span-1 border-0 text-[#FFFFFF] font-semibold-mon py-[15px] text-center inline-block rounded-[100px] text-[16px]"
          >
            Chụp lại
          </button>
          <button
            onClick={() => {
              pushImageToGCS();
            }}
            className="bg-[#5BC2DC] col-span-1 border-0 text-[#FFFFFF] font-semibold-mon py-[20px] text-center inline-block rounded-[100px] text-[16px]"
          >
            Xác nhận
          </button>
        </div>
      </div>
    </div>
  );
}
