import MASK from "../../assets/image/mask_nav.png";
import ICON_HOME from "../../assets/image/Home/icon-home.png";
import ICON_GIFT from "../../assets/image/Home/icon-gifthome.png";
import ICON_HISTORY from "../../assets/image/Home/icon-history.png";
import ICON_PROFILE from "../../assets/image/Home/icon-profile.png";
import ICON_HOME_COLOR from "../../assets/image/Home/icon-home-color.png";
import ICON_GIFT_COLOR from "../../assets/image/Home/icon-gifthome-color.png";
import ICON_HISTORY_COLOR from "../../assets/image/Home/icon-history-color.png";
import ICON_PROFILE_COLOR from "../../assets/image/Home/icon-profile-color.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  BACK_GLOBAL,
  userLocal,
} from "../../services/localService/localService";
import { homeServices } from "../../services/apiService/homeService";
import LOGO from "../../assets/image/mvc.png";
import CLOSE from "../../assets/image/Home/cancel-popup.png";
import PopupLogin from "../../popup/PopupLogin";
import { useQuery } from "react-query";
import CAMERA from "../../assets/image/GuideTakePhoto/camera.png";

export default function Navbar() {
  const navigation = useNavigate();
  const location = useLocation();
  const [path, setPath] = useState<String>();

  const handleHome = (value: string) => {
    if (token === undefined) {
      navigation("/login");
    } else {
      navigation(value);
      setPath(value);
    }
  };
  const handleCamera = () => {
    setIsShowPopupT(true);
  };
  let { token } = userLocal.get();
  const handleListGift = (e: string) => {
    setIsShowGifts(false);
    localStorage.setItem(BACK_GLOBAL, e);
    homeServices
      .GetProfile()
      .then((res: any) => {
        navigation(`/${e}`);
      })
      .catch((err) => {});
  };
  const [isShowPopupGifts, setIsShowGifts] = useState(false);
  const [isShowPopupT, setIsShowPopupT] = useState(false);
  const handleClose = () => {
    setIsShowGifts(false);
    setIsShowPopupT(false);
  };

  const { data } = useQuery<any>({
    queryKey: ["getCampsaign"],
    queryFn: () => homeServices.homeApi(),
  });
  const handleJoin = () => {
    document.body.style.overflow = "unset";
    navigation("/guide-page-picture/0");
  };
  const handleRedirect = () => {
    window.open("https://mvc.com.vn/service/mvc-tech/");
  };
  return (
    <div className="fixed bottom-2 w-full z-10">
      <div
        onClick={handleRedirect}
        className="fixed bottom-0 px-2 z-30 flex w-full justify-between"
      >
        <div className="flex flex-col items-center justify-center">
          <img src={LOGO} className="w-24 " />
        </div>
        <div className="text-[14px] font-light-so">
          Power by <span className="font-medium-so">MVC Tech x UrBox</span>
        </div>
      </div>
      <div className="relative top-0 w-full">
        <div className="relative">
          <img
            src={MASK}
            loading="lazy"
            width={"100%"}
            height={"100%"}
            alt="Image"
            className="opacity-90 relative top-[4px]"
          />
          <div className="bg-navglass absolute -bottom-[8px] h-[81px] max-[400px]:h-[72px] max-[376px]:h-[70px] w-full"></div>
          <div className="absolute top-2 left-1/2 -translate-x-1/2 border-inglassphism"></div>
          <div className="absolute bottom-0 w-full text-[10px] font-regular-so">
            <div className="grid grid-cols-5 w-fill relative">
              <div className="col-span-1 relative top-1">
                <button
                  className="absolute left-1/2 -translate-x-1/2 w-full -top-[2px]"
                  onClick={() => handleHome("/")}
                >
                  <div className="flex justify-center">
                    <img
                      src={
                        location?.pathname === "/" ? ICON_HOME_COLOR : ICON_HOME
                      }
                      className="w-6 h-6"
                      alt="Image"
                    />
                  </div>
                  <div
                    style={
                      location.pathname === "/"
                        ? {
                            color: "#59C2DD",
                          }
                        : {
                            color: "#5D6A85",
                          }
                    }
                    className="flex justify-center mt-1 font-light-so"
                  >
                    Trang chủ
                  </div>
                </button>
              </div>
              <div className="col-span-1 relative top-1 flex justify-center">
                <button
                  className="absolute left-1/2 -translate-x-1/2 w-full -top-[2px]"
                  onClick={() => handleListGift("list-gift")}
                >
                  <div className="flex justify-center ">
                    <div className="relative">
                      <img
                        src={
                          location?.pathname?.includes("/list-gift")
                            ? ICON_GIFT_COLOR
                            : ICON_GIFT
                        }
                        alt="Image"
                        loading="lazy"
                        width={24}
                        height={24}
                        className="relative"
                      />
                      {data?.have_new_gift && (
                        <div className="absolute -top-1 -right-1 bg-blinker w-4 h-4"></div>
                      )}
                    </div>
                  </div>
                  <div
                    style={
                      location.pathname?.includes("/list-gift")
                        ? {
                            color: "#59C2DD",
                          }
                        : {
                            color: "#5D6A85",
                          }
                    }
                    className="flex justify-center mt-1 relative top-0"
                  >
                    Quà của tôi
                  </div>
                </button>
              </div>
              <div className="col-span-1 flex justify-center relative bottom-10 max-[400px]:bottom-8 max-[376px]:bottom-8">
                <button
                  className="w-[60px] h-[60px] rounded-full flex justify-center items-center"
                  onClick={handleCamera}
                >
                  <div className="border-base bg-[#5BC2DC] relative rounded-full shadow-navcontrol1 top-2 w-14 h-14 max-[376px]:w-14 max-[400px]:h-14">
                    {/* <Lottie options={defaultOptions} height={50} width={50} /> */}
                    <div className="flex justify-center items-center h-full">
                      <img src={CAMERA} />
                    </div>
                  </div>
                </button>
                <div className="absolute text-[#5D6A85] -bottom-[24px] max-[400px]:-bottom-[22px] max-[376px]:-bottom-[21px]">
                  Chụp hóa đơn
                </div>
              </div>
              <div className="col-span-1 relative top-1 flex justify-center">
                <button
                  className="absolute left-1/2 -translate-x-1/2 w-full -top-1"
                  onClick={() => handleListGift("list-history")}
                >
                  <div className="flex justify-center">
                    <img
                      src={
                        location?.pathname === "/list-history"
                          ? ICON_HISTORY_COLOR
                          : ICON_HISTORY
                      }
                      loading="lazy"
                      width={24}
                      height={28}
                      alt="Image"
                    />
                  </div>
                  <div
                    style={
                      location.pathname === "/list-history"
                        ? {
                            color: "#59C2DD",
                          }
                        : {
                            color: "#5D6A85",
                          }
                    }
                    className="flex justify-center mt-[6px]"
                  >
                    Lịch sử
                  </div>
                </button>
              </div>
              <div className="col-span-1 relative top-1 flex justify-center">
                <button
                  className="absolute left-1/2 -translate-x-1/2 w-full -top-1"
                  onClick={() => handleListGift("profile")}
                >
                  <div className="flex justify-center">
                    <img
                      src={
                        location?.pathname === "/profile"
                          ? ICON_PROFILE_COLOR
                          : ICON_PROFILE
                      }
                      loading="lazy"
                      className="w-6"
                      width={24}
                      height={28}
                      alt="Image"
                    />
                  </div>
                  <div
                    style={
                      location.pathname === "/profile"
                        ? {
                            color: "#59C2DD",
                          }
                        : {
                            color: "#5D6A85",
                          }
                    }
                    className="flex justify-center mt-[6px]"
                  >
                    Tài khoản
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopupLogin isShow={isShowPopupGifts}>
        <div className="py-5 px-5 text-center relative font-regular-so">
          <div className="uppercase text-[#2D4E68] text-[28px] font-bold-so">
            Thông báo
          </div>
          <div className="text-justify">
            Quý khách vẫn còn lượt chơi! Vui lòng tham gia toàn bộ lượt chơi và
            nhận được tin nhắn thông báo trúng quà từ tổng đài SPVB để nhận quà
            cuối chương trình.
          </div>
        </div>
        <div className="pb-5">
          <button
            className="bg-[#5BC2DC] px-14 py-4 rounded-2xl shadow-item text-white"
            onClick={() => handleListGift("list-gift")}
          >
            Đồng ý
          </button>
        </div>
        <div className="absolute -bottom-20 left-1/2 -translate-x-1/2">
          <img src={CLOSE} onClick={handleClose} />
        </div>
      </PopupLogin>

      <PopupLogin isShow={isShowPopupT}>
        <div className="py-5 px-5 text-center relative font-regular-so">
          <div className="uppercase text-[#2D4E68] text-[28px] font-bold-so">
            Thông báo
          </div>
          <div className="text-left">
            - Hình hóa đơn tham gia chương trình Tích điểm Pepcoin chỉ áp dụng
            trong vòng{" "}
            <span className="text-[#223F95] font-semibold-so">
              03 ngày kể từ thời điểm xuất hóa đơn.
            </span>{" "}
            <br />- Mỗi khách hàng chỉ được đổi quà bằng Pepcoin{" "}
            <span className="text-[#223F95] font-semibold-so">
              tối đa 3 lần/ngày.
            </span>{" "}
            <br />- Mỗi khách hàng chỉ được nhận được{" "}
            <span className="text-[#223F95] font-semibold-so">
              tối đa 500 Pepcoin/tháng.
            </span>
          </div>
        </div>
        <div className="pb-5">
          <button
            className="bg-[#5BC2DC] px-14 py-4 rounded-2xl shadow-item text-white"
            onClick={handleJoin}
          >
            Đồng ý
          </button>
        </div>
        <div className="absolute -bottom-20 left-1/2 -translate-x-1/2">
          <img src={CLOSE} onClick={handleClose} />
        </div>
      </PopupLogin>
    </div>
  );
}
