import PopupOverlay from "../../../../components/BackgroundPopup/PopupOverLay";
import BTNCHANGEGIFT from "../../../../assets/image/Game/DragonSnake/btnchangegift.png";
import BTNCONTINUE from "../../../../assets/image/Game/DragonSnake/btncontinue.png";
import TITLE from "../../../../assets/image/Game/DragonSnake/title_nogift.png";
import Dragon from "../../../../assets/image/Game/DragonSnake/dragon.png";
import FLOWER from "../../../../assets/image/Game/DragonSnake/flower.png";
import FIREWORKS from "../../../../assets/image/Game/DragonSnake/firework.png";
import LoadingButton from "../../../../components/Loading/LoadingButton";
import { DetailsDragonSnake, GiftDragon } from "../../../../models/GameModel";
import { Fragment } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";

export default function PopupGift({
  isPopup,
  game_data,
  gift,
  setPopup,
  disable,
  current,
  mileCurentStone,
  handleClose,
  gift_extra_content,
  gift_reward_image_url,
  gift_name,
  labelChangeGift,
  openExtraGift,
  isLastGift,
}: {
  isPopup: boolean;
  game_data: DetailsDragonSnake;
  gift: GiftDragon;
  setPopup: any;
  disable: boolean;
  current: number;
  mileCurentStone: number;
  handleClose: any;
  gift_extra_content: string;
  gift_reward_image_url: string;
  gift_name: string;
  labelChangeGift: string;
  openExtraGift: boolean;
  isLastGift: boolean;
}) {
  const navigation = useNavigate();
  return (
    <PopupOverlay isShow={isPopup}>
      <div>
        {isPopup && (
          <div className="relative top-10">
            <img
              src={game_data?.popup_header_image_url}
              className="relative z-10 overflow-hidden w-full"
            />
            <div className="relative z-0 -top-[280px] animation-scroll_dragon">
              <img
                src={game_data?.popup_background_image_url}
                className="w-full relative z-10"
              />
              <div
                className="absolute top-1/2 -translate-y-1/2 px-[20%] w-full z-10"
                style={{ color: game_data?.text_color }}
              >
                {isLastGift ? (
                  <div className="font-regular-greatvibes text-[22px]">
                    Chúc mừng bạn đã hoàn thành toàn bộ thử thách của chương
                    trình. Vui lòng kiểm tra tin nhắn trúng thưởng trong mục{" "}
                    <span className="">Quà của tôi.</span>
                    <div>
                      <button
                        onClick={() => navigation("/list-gift")}
                        className="w-[120px] mt-10 h-9 relative"
                      >
                        <img src={BTNCHANGEGIFT} className="h-full w-full" />
                        <div className="uppercase font-bold-so text-[12px] absolute -top-[2px] w-full h-full flex justify-center items-center">
                          Quà của tôi
                        </div>
                      </button>
                    </div>
                  </div>
                ) : (
                  <Fragment>
                    {gift_extra_content !== "" &&
                    gift_extra_content !== "<p><br></p>" &&
                    gift_extra_content !== undefined ? (
                      <div className="relative z-20 w-full flex flex-col items-center justify-center">
                        <img
                          src={TITLE}
                          className="max-w-[180px] relative z-40"
                        />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: gift_extra_content as string,
                          }}
                          className="font-regular-greatvibes text-[24px] mt-5 relative z-20"
                        ></div>
                        <button
                          onClick={() => setPopup(false)}
                          className="bg-[#0013E9] font-medium-so w-[140px] h-10 mt-5 rounded-3xl text-white shadow-item"
                        >
                          ĐỒNG Ý
                        </button>
                      </div>
                    ) : (
                      <Fragment>
                        <div
                          style={{
                            color: game_data?.title_color,
                          }}
                          className="font-bold-so uppercase text-[16px] leading-[21px]"
                        >
                          Chào tết ất tỵ tặng bạn lộc xuân
                        </div>
                        <div className="flex justify-center">
                          <img src={gift_reward_image_url} className="w-24" />
                        </div>
                        <div className="font-bold-so text-[14px] leading-5">
                          {gift_name}
                        </div>
                        <div className="font-light-so text-[11px] leading-[14px] mt-1">
                          {openExtraGift ? (
                            <div>
                              Chúc mừng bạn đã thuộc{" "}
                              <span className="font-bold-so">top 40</span> khách
                              hàng hoàn thành thử thách nhanh nhất. Vui lòng
                              kiểm tra tin nhắn trúng thưởng trong mục{" "}
                              <span className="font-bold-so">Quà của tôi.</span>
                            </div>
                          ) : (
                            `Quý khách vui lòng kiểm tra thông báo quà tặng trong mục
                      Quà của tôi để biết thêm chi tiết nhận quà.`
                          )}
                        </div>
                        <div className="flex justify-around mt-[6px] items-center h-8 font-medium-so">
                          <button
                            onClick={() => handleClose(false)}
                            className="w-[120px] h-9 relative"
                          >
                            <img
                              src={BTNCHANGEGIFT}
                              className="h-full w-full"
                            />
                            <div className="uppercase text-[12px] absolute -top-[2px] w-full h-full flex justify-center items-center">
                              {disable ? (
                                <LoadingButton />
                              ) : (
                                `${labelChangeGift}`
                              )}
                            </div>
                          </button>
                          {(current === 9 && mileCurentStone === 3) ||
                          mileCurentStone === 0 ? null : (
                            <button
                              onClick={() => handleClose(true)}
                              className="w-[120px] h-9 relative"
                            >
                              <img
                                src={BTNCONTINUE}
                                className="h-full w-full"
                              />
                              <div className="text-white text-[12px] absolute -top-[2px] w-full h-full flex justify-center items-center">
                                {disable ? <LoadingButton /> : "NHẢY TIẾP"}
                              </div>
                            </button>
                          )}
                        </div>
                        <div className="mt-1 border-note-dragon p-1 rounded-md">
                          <div className="text-left text-[10px]">
                            - Nhấn ĐỔI QUÀ, bạn sẽ nhận quà và{" "}
                            <span className="font-bold-so text-red-600">
                              quay về Tầng 0 của Cấp đó.
                            </span>
                          </div>
                          <div className="text-left text-[10px]">
                            - Nhấn NHẢY TIẾP, bạn sẽ{" "}
                            <span className="font-bold-so text-red-600">
                              bỏ qua phần quà của Tầng này.
                            </span>
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </div>
              <div className="absolute right-3 bottom-14 z-10">
                <img src={Dragon} className="w-20" />
              </div>
              <div className="absolute right-4 bottom-72 z-10">
                <img src={FLOWER} className="w-20" />
              </div>
              <div className="absolute left-4 bottom-60 z-10">
                <img src={FLOWER} className="w-20" />
              </div>
              <div className="absolute left-3 bottom-40 -z-10">
                <img src={FIREWORKS} className="w-40" />
              </div>
            </div>
          </div>
        )}
      </div>
    </PopupOverlay>
  );
}
