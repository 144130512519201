import { useNavigate } from "react-router-dom";
import ICON_COIN from "../../../assets/image/Home/icon-coin.png";
import { Gifts } from "../../../models/HomePage";
import { formatCoin } from "../../../utils/formatData";

export default function ItemGiftHomePage({
  gift_id,
  gift_name,
  required_points,
  gift_image_url,
  reward_image_url,
  reward_id,
}: Gifts) {
  const navigation = useNavigate();
  const handleDetail = () => {
    navigation(`/details-gift/${reward_id}`);
  };
  return (
    <div className="rounded-2xl mt-3 mr-2" onClick={handleDetail}>
      <div className="bg-[#F2FCFF] rounded-t-2xl">
        <div className="flex justify-center">
          <img
            src={reward_image_url}
            loading="lazy"
            className="w-full object-cover rounded-2xl h-[150px]"
            alt="Image"
          />
        </div>
      </div>
      <div className="bg-[#F2FCFF] rounded-b-2xl px-1 pb-2 relative">
        <div className="font-medium-so pt-2 pl-1 text-reponsive min-h-14">
          {gift_name}
        </div>
        <div className="flex">
          <div className="col-span-2 flex items-center px-1">
            <img
              src={ICON_COIN}
              loading="lazy"
              width={19}
              height={19}
              alt="Image"
            />
          </div>
          <div className="col-span-10">
            <div className="text-[#4E9ABB] flex items-center">
              <div className="text-[20px] font-semibold-so">
                {formatCoin(required_points ? required_points.toString() : "0")}
              </div>
              <div className="text-[13px] font-light-so ml-1 mt-1 text-[#97A2B0]">
                Pepcoin
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 right-0 text-white bg-[#013BE2] px-5 py-1 border-change rounded-tl-3xl rounded-br-2xl">
          Chi tiết
        </div>
      </div>
    </div>
  );
}
