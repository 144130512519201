import { useEffect, useState } from "react";
import PopupOverlay from "../../../components/BackgroundPopup/PopupOverLay";
import { useNavigate, useParams } from "react-router-dom";
import { GiftWheelModel } from "../../../models/GiftModel";

export default function PopupSpecialReceive({
  isPopupGift,
  gift,
  amountPlay,
  handleClosePopup,
  isLoading,
}: {
  isPopupGift: boolean;
  gift: GiftWheelModel;
  amountPlay: number;
  handleClosePopup: () => void;
  isLoading: boolean;
}) {
  let game_type = window.location.pathname.split("/")[1];
  const game = JSON.parse(localStorage.getItem("GAME_DATA_LOCAL") || "{}");
  console.log(gift);
  console.log(game);
  //   const handleClosePopup = () => {

  //     setIsLoading(true);
  //   };
  return (
    <PopupOverlay isShow={isPopupGift}>
      <div className="px-5">
        <div
          style={{
            backgroundImage: `url(${game?.gift_frame_url})`,
            backgroundPosition: "cover",
            backgroundSize: "100% 100%",
            minHeight: "50vh",
            position: "relative",
            paddingTop: "70px",
            left: "-50%",
            transform: "translateX(50%)",
            backgroundRepeat: "no-repeat",
          }}
          className="left-1/2 -translate-x-1/2"
        >
          <div className="absolute left-1/2 -translate-x-1/2 -top-16 w-48">
            <img src={game?.claw_image_url} />
          </div>
          <div className="w-full relative">
            <img
              src={
                gift?.no_gift
                  ? game?.title_no_gift_image_url
                  : game?.title_result_image_url
              }
              className="relative -top-10 w-9/12 left-1/2 -translate-x-1/2"
            />
            <div className="relative -top-10">
              <img
                src={gift?.gift_square_image_url}
                className="w-7/12 relative top-1 left-1/2 -translate-x-1/2"
              />
            </div>
            <div className="relative -top-10 px-8">
              <div
                className="text-white font-semibold-so text-[21px] leading-[23px] uppercase my-4"
                style={{ color: game?.light_color }}
              >
                {!gift?.no_gift && gift?.gift_name}
              </div>
              <p
                className="font-light-so text-[13px] mt-1 leading-[18px]"
                style={{ color: game?.text_color }}
                dangerouslySetInnerHTML={{
                  __html: gift?.instructions_redeeming_rewards,
                }}
              ></p>
              <button
                className="w-full relative top-1"
                style={{ color: game?.text_color }}
                onClick={isLoading ? () => {} : handleClosePopup}
              >
                <img
                  src={game?.button_bottom_image_url}
                  className="w-8/12 relative left-1/2 -translate-x-1/2"
                />
                <label
                  style={{ color: game?.button_text_color }}
                  className={`${
                    game_type === "aqua_pickup"
                      ? "font-bold-so"
                      : "font-medium-air"
                  } absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-[20px] w-full`}
                >
                  {amountPlay === 0 ? (
                    <div className="mt-1 uppercase">Quà của tôi</div>
                  ) : (
                    <>
                      Còn <span className="text-[26px]">{amountPlay}</span> lượt
                      chơi
                    </>
                  )}
                </label>
              </button>
            </div>
          </div>
        </div>
      </div>
    </PopupOverlay>
  );
}
