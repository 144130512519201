import { Fragment, useEffect, useState } from "react";
import TEN_LUA from "../../assets/image/Popup/countdown.png";
import PHONE from "../../assets/image/Popup/phone.png";
import ContactPhoneZalo from "../ContactPhoneZalo/ContactPhoneZalo";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useNavigate } from "react-router-dom";
import { homeServices } from "../../services/apiService/homeService";
import { ListReceipt } from "../../models/GameModel";
import PopupConfirm from "../../pages/GuideTakeAPhoto/UrgentPageReviewBill/PopupConfirm";
import ErrorBill from "../../pages/GuideTakeAPhoto/UrgentPageReviewBill/ErrorBill";
import PopupConfirmSting from "../../pages/GuideTakeAPhoto/UrgentPageReviewBill/PopupConfirmSting";
import BTN_HOTLINE from "../../assets/image/Popup/bg-hotline.png";
import DRAGONLOADING from "../../assets/image/Game/DragonSnake/dragonloading.png";

export default function CountDown() {
  const { hotline } = JSON.parse(
    localStorage.getItem("HOME_DATA_LOCAL") || "{}"
  );
  const review_image = localStorage.getItem("REVIEW_IMG");
  const sale_id = JSON.parse(localStorage.getItem("SALE_ID") || "{}");
  const navigation = useNavigate();
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [count, setCount] = useState(0);
  const [isError, setIsError] = useState(false);
  const [messError, setMessError] = useState("");
  const [isConfirm, setConfirm] = useState(false);
  const [listReceipt, setListReceipt] = useState<ListReceipt | undefined>(
    undefined
  );

  const [isCampaignSting, setIsCampaignSting] = useState(false);
  const [checkLast, setCheckLast] = useState(false);
  const [secondCount, setSecondCount] = useState(60);
  const [checkCampaign, setCheckCampaign] = useState("");
  useEffect(() => {
    if (!isError) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            if (count === 1) {
              setMinutes(0);
              setSeconds(0);
              setSecondCount(0);
              clearInterval(interval);
            } else if (count === 0) {
              setMinutes(1);
              setSeconds(0);
              setSecondCount(60);
              setCount(count + 1);
            }
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);
      if (
        seconds === 2 ||
        seconds === 10 ||
        seconds === 20 ||
        seconds === 30 ||
        seconds === 40 ||
        seconds === 50 ||
        seconds === 59
      ) {
        let lastCall = false;
        if (minutes === 0 && seconds === 2 && count === 1) {
          lastCall = true;
          setCheckLast(true);
        }
        homeServices
          .RecallSubmitReceiptApi(sale_id as string, lastCall)
          .then((res: any) => {
            const checkCampaignSting = res?.rewards?.filter(
              (x: any) =>
                x.campaign_type.toLowerCase().includes("special") ||
                x.campaign_type.toLowerCase().includes("aqua")
            );
            const hasSting = res?.rewards?.some(
              (x: any) => x.campaign_type === "special"
            );
            const hasAqua = res?.rewards?.some(
              (x: any) => x.campaign_type === "aqua"
            );

            if (res?.auto) {
              setConfirm(true);
              setMinutes(0);
              setSeconds(0);
              setListReceipt(res);
              if (checkCampaignSting.length > 0 && !(hasSting && hasAqua)) {
                setIsCampaignSting(true);
                if (hasSting) {
                  setCheckCampaign("sting_pickup");
                } else if (hasAqua) {
                  setCheckCampaign("aqua_pickup");
                }
              } else {
                setIsCampaignSting(false);
              }
              // setDisableRedirect(true);
              clearInterval(interval);
            }
          })
          .catch((err) => {
            setMessError(err);
            setIsError(true);
          });
      }
      return () => {
        clearInterval(interval);
      };
    }
  });
  const statusConfirm = localStorage.getItem("STATUS_CONFIRM");
  useEffect(() => {
    if (statusConfirm === "TRUE") {
      navigation("/");
    }
  }, []);

  const renderTime = ({ remainingTime }: any) => {
    const minutes = Math.floor((remainingTime % 3600) / 60);
    const seconds = remainingTime % 60;
    if (remainingTime === 0 && count === 1) {
      if (!isConfirm) {
        // navigation("/bill-invalid");
      }
      return (
        <div className="timer text-[#4E9ABB] font-semibold-so text-[20px]">
          Hết giờ ...
        </div>
      );
    }
    return (
      <div className="countdown-text text-[#4E9ABB] text-[36px] font-bold-so flex justify-center">{`${minutes}:${
        seconds < 10 ? "0" + seconds : seconds
      }`}</div>
    );
  };
  const [checkPush, setCheckPush] = useState(false);
  const handleUrgent = () => {
    setCheckPush(true);
    if (!checkPush) {
      homeServices
        .PushReviewReceiptApi(sale_id !== undefined ? sale_id : ("" as string))
        .then((res: any) => {
          navigation("/urgent-page");
        })
        .catch((err) => {});
    }
  };
  const handleClose = () => {
    setIsError(false);
    navigation("/guide-take-photo/0");
  };
  return (
    <div className="background-result min-h-screen relative">
      {isError ? (
        <ErrorBill
          title={`${checkLast ? "" : "Hóa đơn không hợp lệ"}`}
          isShow={isError}
          messError={messError}
          handleClose={handleClose}
          labelBtn="Đồng ý"
          imageError={review_image as string}
        />
      ) : (
        <div className="absolute top-1/2 -translate-y-1/2 flex flex-col items-center ">
          <div className="font-bold-so text-[#ffffff] text-center flex">
            Vui lòng đợi {count === 1 && "thêm"}
          </div>
          <div
            className={`${count === 1 ? "mt-0" : "mt-10"} flex justify-center `}
          >
            <img src={TEN_LUA} className="w-10/12" />
          </div>
          <div className="font-regular-so text-[#ffffff] text-[14px] text-center px-10">
            {count === 0
              ? "Mong quý khách thông cảm, hóa đơn của bạn đang được hệ thống xử lý"
              : "Mong quý khách thông cảm, hệ thống cần thêm thời gian để đọc hóa đơn"}
          </div>
          <div className="relative mt-16 bg-white rounded-full">
            <CountdownCircleTimer
              isPlaying
              duration={secondCount}
              colors={["#4E9ABB", "#4E9ABB", "#4E9ABB", "#bdbdbd"]}
              colorsTime={[secondCount, 6, 3, 0]}
              onComplete={() => ({ shouldRepeat: true, delay: 1 })}
            >
              {renderTime}
            </CountdownCircleTimer>
            <div className="absolute -z-50 left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 w-[350px]">
              <img src={DRAGONLOADING} className="w-96" />
            </div>
          </div>
          {count === 1 && (
            <div className="mt-10">
              <button
                onClick={() => {
                  handleUrgent();
                }}
                className={`${
                  checkPush ? "bg-[#bdbdbd]" : "bg-[#5BC2DC]"
                }  w-60 shadow-item text-[#FFFFFF] font-semibold-mon py-[20px] text-center rounded-[15px] border-first`}
              >
                Ưu tiên duyệt
              </button>
            </div>
          )}

          <div className="relative top-10">
            <div className="relative">
              <img src={BTN_HOTLINE} className="relative" />
            </div>
            <div className="absolute flex items-center top-1/2 -translate-y-1/2 w-full justify-center">
              <div>
                <img src={PHONE} />
              </div>
              <div className="text-[#ffffff] font-light-so ml-2">
                Liên hệ Hotline{" "}
                <span className="font-medium-so">
                  <a href={`tel:${hotline}`}>{hotline}</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {listReceipt && (
        <Fragment>
          {isCampaignSting ? (
            <PopupConfirmSting
              isShow={isConfirm}
              listReceipt={listReceipt}
              setConfirm={setConfirm}
              game_type={checkCampaign}
            />
          ) : (
            <PopupConfirm
              isShow={isConfirm}
              listReceipt={listReceipt}
              setConfirm={setConfirm}
            />
          )}
        </Fragment>
      )}
      <ContactPhoneZalo />
    </div>
  );
}
