import DragonEven from "./DragonEven";
import LABEL_LEVEL from "../../../../assets/image/Game/DragonSnake/label_level.png";
import GOLDEN_SPECIAL from "../../../../assets/image/Game/DragonSnake/golden_special.png";
import { TowerDetails } from "../../../../models/GameModel";
import NOGIFT from "../../../../assets/image/Game/DragonSnake/nogift.png";

export default function Cloud({
  location,
  section,
  highlight,
  animation,
  background,
}: {
  location: string;
  section: string;
  highlight: string;
  animation: boolean;
  background: TowerDetails;
}) {
  const { game_data } = JSON.parse(
    localStorage.getItem("DATA_LEADER_BOARD") || "{}"
  );
  return (
    <div
      className={`${
        location == "right" ? "justify-end" : "justify-start"
      } flex w-full p-5 mt-16 relative`}
    >
      <div
        className={`${
          section == "0" ? "mb-40" : section == "9" ? "mt-40" : ""
        } relative`}
      >
        <div className="relative">
          <img src={game_data?.cloud_image_url} className="relative z-10" />
          <div className="absolute -top-16 left-1/2 -translate-x-1/2 z-10 w-32">
            <img
              src={
                background?.gift_square_image_url
                  ? background?.gift_square_image_url
                  : NOGIFT
              }
              className="w-full"
            />
          </div>
        </div>
        <div className="absolute z-20 left-1/2 -translate-x-1/2 flex justify-center items-center top-[58%] -translate-y-1/2">
          <div className="w-28 relative">
            <img src={LABEL_LEVEL} className="h-8 w-28" />
            <div className="absolute top-[45%] -translate-y-1/2 text-white left-7 text-[12px]">
              TẦNG
            </div>
            <div className="absolute -top-1 right-0">
              <div className="relative">
                <img src={GOLDEN_SPECIAL} />
                <div className="absolute text-white font-semibold-so top-[43%] -translate-y-1/2 left-1/2 -translate-x-1/2">
                  {section}
                </div>
              </div>
            </div>
          </div>
        </div>
        {highlight === section && (
          <DragonEven section={section} animation={animation} />
        )}
      </div>
    </div>
  );
}
