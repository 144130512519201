import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useEffect, useState } from "react";
import "../../assets/css/Login.css";
import { LoginServices } from "../../services/apiService/LoginService";
import {
  BACK_GLOBAL,
  userLocal,
} from "../../services/localService/localService";
import BACK_HEADER from "../../assets/image/Header/back-header.png";
import HeaderFrame from "../../components/HeaderFrame/HeaderFrame";
import { setAuthorization } from "../../services/apiService/configURL";
import EYEOFF from "../../assets/image/Login/eye-off.png";
import EYEON from "../../assets/image/Login/eye-on.png";
import FOOTER from "../../assets/image/Login/footer.png";
import { useQuery } from "react-query";
import { homeServices } from "../../services/apiService/homeService";
import LoadingButton from "../../components/Loading/LoadingButton";
import { LoginAPIModel } from "../../models/LoginModel";
import PopupError from "../../components/Popup/PopupError";

export default function ValidatePassword() {
  let { token } = userLocal.get();

  const phone = localStorage.getItem("PHONE");
  const navigation = useNavigate();
  const { data } = useQuery<any>({
    queryKey: ["getCampsaign"],
    queryFn: () => homeServices.homeApi(),
  });
  const handleBack = () => {
    navigation("/login");
  };
  useEffect(() => {
    if (token) {
      navigation("/");
    }
  }, []);
  const [isForgot, setIsForgot] = useState(false);
  const [statusError, setStatusError] = useState(false);
  const [isError, setIsError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEye, setIsEye] = useState(false);
  const onClickEye = () => {
    setIsEye(!isEye);
  };
  const getBack = localStorage.getItem(BACK_GLOBAL);
  const onSubmit = (data: any) => {
    const datatest = Object.assign(data, { login: phone });
    setIsLoading(true);
    LoginServices.LoginApi(datatest)
      .then((res: any) => {
        setAuthorization(res.token);
        userLocal.set(res);
        navigation(`/${getBack}`);
        localStorage.setItem("CHECK_STATUS", "TRUE");
      })
      .catch((err) => {
        setIsError(err);
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
  });
  const handleForgotPass = () => {
    const data = {
      phone: phone,
    };
    LoginServices.GetOTPPassword(data as LoginAPIModel)
      .then((res) => {
        navigation(`/validate-otp/forgot-password/login`);
      })
      .catch((err) => {
        setIsError(err);
        setStatusError(true);
        console.log(err);
      });
  };
  const handleClose = () => {
    setStatusError(false);
  };
  return (
    <div className="">
      <HeaderFrame
        footer={FOOTER}
        title="NHẬP MẬT KHẨU"
        iconBack={BACK_HEADER}
        handleBack={handleBack}
      >
        <div className="pb-5 px-3">
          <form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
            <label className="text-[#5BC2DC] font-semibold-so">
              Mật khẩu của bạn
            </label>
            <div className="rounded-lg bg-white grid grid-cols-12 px-2 mt-1 border-itemlogin">
              <input
                type={!isEye ? "password" : ""}
                placeholder="Vui lòng nhập mật khẩu"
                className="w-full col-span-11 pl-3 h-12 z-0 font-light-so rounded-lg text-[14px] input-hidden"
                {...register("password", {
                  required: "Không được để trống",
                })}
              />
              <div
                className="col-span-1 flex items-center"
                onClick={onClickEye}
              >
                {!isEye ? <img src={EYEOFF} /> : <img src={EYEON} />}
              </div>
            </div>
            <div className="font-normal z-0 -mt-1 text-[red] text-[13px] text-center">
              <ErrorMessage
                errors={errors}
                name="password"
                render={({ messages }) => {
                  return messages
                    ? Object.entries(messages).map(([type, message]) => (
                        <p
                          key={type}
                          className="pt-2.5 -mt-1 z-0 rounded-bl-xl rounded-br-xl"
                        >
                          {message}
                        </p>
                      ))
                    : null;
                }}
              />
            </div>
            {isError ? (
              <div className="font-normal z-0 mt-3 text-[red] text-[13px] text-center">
                {isError}
              </div>
            ) : null}
            <div className="text-right flex justify-end w-full text-[#2D4E68] mt-3">
              <div
                className="text-[12px] font-light-so w-32"
                onClick={handleForgotPass}
              >
                Quên mật khẩu?
              </div>
            </div>
            <div className="mt-10">
              <div className="mt-3 flex justify-center">
                {isLoading ? (
                  <div className="bg-[#5BC2DC] text-center w-40 px-8 font-semibold-so py-[15px] text-[14px] rounded-lg text-white">
                    <LoadingButton />
                  </div>
                ) : (
                  <button className="bg-[#5BC2DC] w-40 px-8 font-semibold-so py-4 text-[14px] rounded-lg text-white">
                    Đăng nhập
                  </button>
                )}
              </div>
            </div>
          </form>
          <div className="mt-5 font-light-so text-[#424242] text-[12px] text-center px-2">
            Gọi điện cho Hotline
            <span className="text-[#4E9ABB] text-[14px] font-medium-so">
              {" "}
              <a href={`tel:${data?.hotline}`}>{data?.hotline}</a>{" "}
            </span>
            để được hỗ trợ
          </div>
        </div>
        <PopupError
          messError={isError}
          isShow={statusError}
          handleClose={handleClose}
          labelBtn="Đóng"
        />
        {/* <ContactPhoneZalo /> */}
      </HeaderFrame>
    </div>
  );
}
