import ItemRating from "../../../../components/Popup/GameLuckyMachine/ItemRating";
import PopupPlantTree from "../../../../components/Popup/GameLuckyMachine/PopupPlantTree";
import GIFT from "../../../../assets/image/Game/PlantTree/gift-coin.png";
import { useEffect, useState } from "react";
import { GameServices } from "../../../../services/apiService/GameService";
import { useParams } from "react-router-dom";
import { UserLeaderBoard } from "../../../../models/LeaderBoard";
import { LeaderBoardServices } from "../../../../services/apiService/LeaderBorad";

export default function LeaderBoard({
  frameBow,
  showTab,
  handleOpenBoard,
  lstLeaderBoard,
  imgTitle,
  title,
  idPeriod,
}: {
  frameBow: string;
  showTab: Boolean;
  handleOpenBoard: () => void;
  lstLeaderBoard: any;
  imgTitle: string;
  title?: string;
  idPeriod?: string;
}) {
  const race_id = localStorage.getItem("RACE_ID");
  const top = localStorage.getItem("TOP");
  const check = localStorage.getItem("CHECK");
  const [currents, setCurrent] = useState<string>(top ? top : "100");
  const [listBoard, setListBoard] = useState<any>();
  const [leaderBoard, setLeaderBoard] = useState<[]>(
    lstLeaderBoard?.leader_boards
  );
  const [customerLeaderBoard, setCustomerLeaderBoard] =
    useState<UserLeaderBoard>(lstLeaderBoard?.consumer_leader_board);
  const [raceContent, setRaceContent] = useState("");

  const { id } = useParams();
  const handleClick = (e: any, status_gifts: string) => {
    setCurrent(e.target.id);

    getListBoard(id as string, e.target.id, race_id as string);
  };
  const [number, setNumber] = useState(parseInt(race_id as string));
  useEffect(() => {
    const number = parseInt(race_id ? race_id : "0");
    if (check === "100") {
      if (number % 2 === 0) {
        setNumber(number);
      } else {
        setNumber(number - 1);
      }
    } else {
      if (number % 2 !== 0) {
        setNumber(number);
      } else {
        setNumber(number + 1);
      }
    }
  }, []);
  useEffect(() => {
    getListBoard(id as string, currents, race_id as string);
  }, [showTab]);
  const getListBoard = (id: string, currents: string, race_id?: string) => {
    if (currents === "100") {
      if (check === "10") {
        GameServices.GetLeaderBoard(
          id as string,
          currents,
          (number + 1).toString()
        )
          .then((res: any) => {
            setLeaderBoard(res?.leader_boards);
            setCustomerLeaderBoard(res?.consumer_leader_board);
            setRaceContent(res?.race_content);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        GameServices.GetLeaderBoard(id as string, currents, number.toString())
          .then((res: any) => {
            setLeaderBoard(res?.leader_boards);
            setCustomerLeaderBoard(res?.consumer_leader_board);
            setRaceContent(res?.race_content);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      if (check === "10") {
        GameServices.GetLeaderBoard(id as string, currents, number.toString())
          .then((res: any) => {
            setLeaderBoard(res?.leader_boards);
            setCustomerLeaderBoard(res?.consumer_leader_board);
            setRaceContent(res?.race_content);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        GameServices.GetLeaderBoard(
          id as string,
          currents,
          (number - 1).toString()
        )
          .then((res: any) => {
            setLeaderBoard(res?.leader_boards);
            setCustomerLeaderBoard(res?.consumer_leader_board);
            setRaceContent(res?.race_content);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  return (
    <PopupPlantTree
      title={title}
      frameBow={frameBow}
      imgTitle={imgTitle}
      isShow={showTab}
      handleOpenBoard={handleOpenBoard}
    >
      <div>
        <div className="fixed z-10 w-10/12 h-[16%] left-1/2 -translate-x-1/2 bg-white rounded-t-[40px]"></div>
        <div className="fixed z-10 w-10/12 left-1/2 -translate-x-1/2 rounded-lg flex justify-center top-[18%] max-[414px]:top-[19%] text-white">
          <div className="tabImg bg-[#FFFFFF] flex justify-between items-center align-middle rounded-t-3xl">
            <div className="flex justify-center w-40 flex-auto">
              <button
                key="100"
                id={"100"}
                disabled={currents === "100"}
                onClick={(e) => handleClick(e, "quotation")}
                className="font-semibold-mon leading-4 mt-[8px] text-[11px] "
              >
                Top 100
              </button>
            </div>
            <div className="flex justify-center w-40 flex-auto">
              <button
                key="10"
                id={"10"}
                disabled={currents === "10"}
                onClick={(e) => handleClick(e, "done")}
                className="font-semibold-mon leading-4 mt-[8px] text-[12px]"
              >
                Top 10
              </button>
            </div>
          </div>
        </div>
        <div className="relative top-[80px] px-4 pb-28">
          <div
            className="font-light-so mb-2 pt-2 text-[13px]"
            dangerouslySetInnerHTML={{
              __html: raceContent,
            }}
          ></div>
          {leaderBoard?.map((res: UserLeaderBoard) => (
            <ItemRating data={res} />
          ))}
        </div>
      </div>
      <div className="fixed -bottom-12 px-[2px] pt-[3px] pb-[2px] rounded-[23px] text-black w-[82%] left-1/2 -translate-x-1/2 bg-[#FFCF6C] z-30">
        <div className="relative w-full">
          <div className="bg-[#DFF7FF] rounded-t-[23px] rounded-b-[18px] h-9"></div>
          <div className="bg-[#DFF7FF] rounded-t-[18px] rounded-b-[23px] rounded-3xl h-9 -mt-[1px]"></div>
          <div className="absolute py-3 h-full top-0 grid grid-cols-12 px-3 gap-1 rounded-3xl w-full">
            <div className="relative top-0 w-12 h-12 col-span-2">
              <img src={customerLeaderBoard?.image_url} className="w-12 h-12" />
              <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 font-semibold-so">
                {customerLeaderBoard?.top}
              </div>
            </div>
            <div className="col-span-9">
              <div className="grid grid-cols-12">
                <div className="text-[16px] text-left col-span-9 font-semibold-so text-[#242E49]">
                  {customerLeaderBoard?.consumer_name}
                </div>
                <div className="text-[#4E9ABB] col-span-3 font-semibold-so flex items-center justify-end">
                  {customerLeaderBoard?.point} xô
                </div>
              </div>
              <div className="grid grid-cols-12 mt-1">
                <div className="text-[#59C2DD] col-span-4 font-light-so text-[14px] flex items-center justify-start">
                  {customerLeaderBoard?.consumer_phone}
                </div>
                <div className="text-[12px] col-span-8 text-[#242E49] flex items-center justify-end">
                  {customerLeaderBoard?.lasted_update}
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <div className="text-[#4E9ABB] font-semibold-so flex items-center h-full">
                <img src={GIFT} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PopupPlantTree>
  );
}
