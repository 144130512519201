import BackgroundGame from "./BackgroundGame/BackgroundGame";
import StartLoading from "./Loading/StartLoading";
import BUTTON from "../../../assets/image/Game/PlantTree/button-join.png";
import PEPSI from "../../../assets/image/Game/PlantTree/olong.png";
import OLONG from "../../../assets/image/Game/PlantTree/pepsi.png";
import { useEffect, useState } from "react";
import Tree from "./Tree/Tree";
import Water from "./Loading/Water";
import BACK from "../../../assets/image/Header/back-header.png";
import { GameServices } from "../../../services/apiService/GameService";
import { useNavigate, useParams } from "react-router-dom";
import {
  DetailsPlantTree,
  PointGamePlantTree,
} from "../../../models/GameModel";
import OverlayPopup from "../../../components/OverlayPopup/OverlayPopup";
import Quest from "./PopupGame/Quest";
import LeaderBoard from "./PopupGame/LeaderBoard";
import TC from "./PopupGame/TC";
import Footer from "../../../components/NavbarMenu/Footer";

export default function PlantTree() {
  const { id, type } = useParams();
  const [isOpenCan, setIsOpenCan] = useState(false);
  const [isWaterRun, setIsWaterRun] = useState(false);
  const [isDisableCan, setIsDisableCan] = useState(false);
  const [isDisableAnimation, setIsDisableAnimation] = useState(false);
  const [showTab, setShowTab] = useState(type ? true : false);
  const [showLeaderBoard, setShowLeaderBoard] = useState(false);
  const [isseedBag, setSeedBag] = useState(false);
  const [isSedd, setSeed] = useState(false);
  const [isTc, setTc] = useState(false);
  const [dataGame, setDataGame] = useState<DetailsPlantTree>();
  const [contentDetails, setContentDetails] = useState("");
  const [dataPoint, setDataPoint] = useState<PointGamePlantTree>();
  const [percentCurrent, setPercentCurrent] = useState<number>(0);
  const [total, setTotal] = useState<number>(1);
  const [tree, setTree] = useState<string>();
  const [lstLeaderBoard, setlstLeaderBoard] = useState();

  const navigation = useNavigate();
  const [lstQuest, setLstQuest] = useState();
  const [current, setCurrent] = useState(0);
  const { hotline } = JSON.parse(
    localStorage.getItem("HOME_DATA_LOCAL") || "{}"
  );
  const handleOpenStatus = () => {
    setIsDisableAnimation(true);
    if (!isDisableAnimation) {
      if (!isDisableCan && bucket !== 0) {
        setIsOpenCan(true);
        setTimeout(() => {
          setCurrent(current - 1);
          setPercentCurrent(percentCurrent + 1);

          GameServices.UpdateUseWater(id as string)
            .then((res: any) => {
              setTree(res?.current_image_url);
              setBucket(res?.remaining_experiences);
              getPointGame();
            })
            .catch((err) => {
              console.log(err);
            });
        }, 2200);
        setTimeout(() => {
          setIsWaterRun(true);
        }, 2000);
        setTimeout(() => {
          setIsOpenCan(false);
          setIsWaterRun(false);
          setIsDisableAnimation(false);
        }, 4000);
      }
    }
  };
  useEffect(() => {
    GameServices.GetGamePlantTree(id as string)
      .then((res: any) => {
        console.log(res);
        localStorage.setItem("DATA_LEADER_BOARD", JSON.stringify(res));
        setDataGame(res?.game_data);
        setContentDetails(res?.content_details);
      })
      .catch((err) => {
        console.log(err);
      });
    getPointGame();
    getListQuest();
  }, []);
  const [bucket, setBucket] = useState<number>(0);

  const getPointGame = () => {
    GameServices.GetJoinGamePlantTree(id as string)
      .then((res: any) => {
        if (!res?.dataPoint?.already_join) {
          seedBag();
        }
        getListBoard(id as string, "100");

        setIsDisableCan(res?.max_level);
        setDataPoint(res);
        setTree(res?.current_image_url);
        setBucket(res?.remaining_experiences);
        setTotal(res?.lv_required_exp);
        setCurrent(res?.remaining_exp_next_lv);
        setPercentCurrent(
          ((res?.lv_required_exp as number) -
            res?.remaining_exp_next_lv) as number
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleOpenBoard = () => {
    localStorage.removeItem("RACE_ID");
    localStorage.removeItem("TOP");
    localStorage.removeItem("CHECK");
    getListBoard(id as string, "100");
    if (type) {
      navigation(`/list-leader-board-plant-tree/${id}`);
    } else {
      setShowTab(!showTab);
      getListBoard(id as string, "100");
    }
  };
  const handleOpenLeaderBoard = () => {
    setShowLeaderBoard(!showLeaderBoard);
    getListQuest();
    getPointGame();
  };
  const seedBag = () => {
    setSeedBag(true);
    setTimeout(() => {
      setSeed(true);
      setTimeout(() => {
        getPointGame();
      }, 2000);
    }, 2000);
  };
  const getListQuest = () => {
    GameServices.ListQuest(id as string)
      .then((res: any) => {
        setLstQuest(res?.quests);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getListBoard = (id: string, currents: string) => {
    GameServices.GetLeaderBoard(id as string, currents)
      .then((res: any) => {
        setlstLeaderBoard(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleOpenTC = () => {
    setTc(!isTc);
  };
  const handleBack = () => {
    navigation(`/intro_plant_tree/${id}`);
  };
  const handleListRewards = () => {
    navigation(`/list-leader-board-plant-tree/${id}`);
  };
  // console.log(dataPoint);

  return (
    <BackgroundGame
      bg_game={dataGame?.home_screen_url as string}
      title={dataGame?.title_image_url as string}
    >
      {/* <div className="absolute top-40 left-5">
        <div>
          <img src={OLONG} className="w-16 animation-olong" />
        </div>
      </div>
      <div className="absolute top-40 right-5">
        <div>
          <img src={PEPSI} className="w-20 animation-pepsi" />
        </div>
      </div> */}
      <div className="absolute top-[2%] w-full flex justify-between px-2">
        <button onClick={handleBack}>
          <img src={BACK} className="w-8" />
        </button>
        <button onClick={handleOpenTC}>
          <div className="relative top-0 w-20">
            <img src={dataGame?.frame_icon_url} />
            <div className="absolute w-full top-0">
              <img
                src={dataGame?.tnc_icon_url}
                className="w-8/12 top-0 relative left-1/2 -translate-x-1/2"
              />
            </div>
            <div className="absolute bottom-[12px] text-white text-[8px] font-regular-so leading-[8px] left-1/2 -translate-x-1/2 w-full">
              Thể lệ <br />
              chương trình
            </div>
          </div>
        </button>
      </div>
      <div className="absolute w-full text-center top-[61vh] -translate-y-1/2">
        <Tree tree={dataPoint?.already_join ? (tree as string) : ""} />
        {!isDisableCan && (
          <StartLoading
            width={(percentCurrent / total) * 100}
            title={`Còn ${current}/${total} xô nước nữa`}
            gift={dataPoint?.next_image_url as string}
          />
        )}
        {!isDisableCan ? (
          <div className={`showTab absolute -bottom-[190px] w-full`}>
            <div className="grid grid-cols-9">
              <button
                className="col-span-2 flex items-end"
                onClick={handleOpenBoard}
              >
                <div className="relative top-0">
                  <img src={dataGame?.frame_icon_url} />
                  <div className="absolute w-full top-0">
                    <img
                      src={dataGame?.leader_board_icon_url}
                      className="w-10/12 -top-3 relative left-1/2 -translate-x-1/2"
                    />
                  </div>
                  <div className="absolute bottom-[10px] text-white text-[9px] font-regular-so leading-[10px] left-1/2 -translate-x-1/2 w-full">
                    Bảng xếp <br />
                    hạng
                  </div>
                </div>
              </button>
              <button
                className="col-span-2 flex items-end"
                onClick={handleOpenLeaderBoard}
              >
                <div className="relative top-0 w-full">
                  <img src={dataGame?.frame_icon_url} className="w-full" />
                  <div className="absolute w-full top-0">
                    <img
                      src={dataGame?.quest_icon_url}
                      className="w-10/12 -top-3 relative left-1/2 -translate-x-1/2"
                    />
                  </div>
                  <div className="absolute bottom-[10px] text-white text-[9px] font-regular-so leading-[10px] left-1/2 -translate-x-1/2 w-full">
                    Nhiệm vụ <br />
                    hàng ngày
                  </div>
                </div>
              </button>
              <button
                className="col-span-2 flex items-end"
                onClick={handleListRewards}
              >
                <div className="relative top-0">
                  <img src={dataGame?.frame_icon_url} />
                  <div className="absolute w-full top-0">
                    <img
                      src={dataGame?.result_icon_url}
                      className="w-10/12 -top-3 relative left-1/2 -translate-x-1/2"
                    />
                  </div>
                  <div className="absolute bottom-[10px] text-white text-[9px] font-regular-so leading-[10px] left-1/2 -translate-x-1/2 w-full">
                    Danh sách <br />
                    trúng thưởng
                  </div>
                </div>
              </button>
              <div className="col-span-3 relative flex justify-center">
                <div className="w-full h-[100px] relative">
                  <div className="absolute bottom-3 left-1/2 -translate-x-1/2 w-[90px] h-[90px] bg-watering-can">
                    <div
                      id="box"
                      className={`${
                        isOpenCan ? "animation-wateringcan z-20" : ""
                      } relative rotation-wateringcan w-[120px] h-[100px] right-12`}
                      onClick={handleOpenStatus}
                    >
                      <img
                        src={
                          dataPoint?.already_join && dataGame?.bucket_icon_url
                        }
                        className="w-[120px] relative -top-8 z-20 -right-3"
                      />
                      {isWaterRun && <Water color={"#DEFAFD"} />}
                    </div>
                  </div>
                  <div className="absolute bottom-6 w-[100px]"></div>
                  <div className="absolute bottom-0 w-full">
                    <img src={BUTTON} />
                    <div className="absolute font-bold-so left-1/2 -translate-x-1/2 top-[45%] -translate-y-1/2 w-full text-white">
                      X{bucket} xô nước
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-[12px] text-black">
              Gọi điện cho Hotline{" "}
              <a
                href={`tel:${hotline}`}
                className="font-bold-so text-[#1E9E52]"
              >
                {hotline}
              </a>{" "}
              để được hỗ trợ
            </div>
          </div>
        ) : (
          <>
            <div className="absolute -bottom-[35px] text-black text-[12px] leading-4 px-14">
              Chúc mừng bạn đã Thu hoạch thành công! Tham gia vào chặng đua
              tháng sau nhé
            </div>
            <div className={`showTab absolute -bottom-[165px] w-full`}>
              <div className="grid grid-cols-9 gap-2">
                <button
                  className="col-span-2 flex items-end"
                  onClick={handleOpenBoard}
                >
                  <div className="relative top-0">
                    <img src={dataGame?.frame_icon_url} />
                    <div className="absolute w-full top-0">
                      <img
                        src={dataGame?.leader_board_icon_url}
                        className="w-7/12 top-1 relative left-1/2 -translate-x-1/2"
                      />
                    </div>
                    <div className="absolute bottom-[10px] text-white text-[9px] font-regular-so leading-[10px] left-1/2 -translate-x-1/2 w-full">
                      Bảng xếp <br />
                      hạng
                    </div>
                  </div>
                </button>
                <button className="col-span-2 flex items-end filter grayscale">
                  <div className="relative top-0 w-full">
                    <img src={dataGame?.frame_icon_url} className="w-full" />
                    <div className="absolute w-full top-0">
                      <img
                        src={dataGame?.quest_icon_url}
                        className="w-7/12 top-1 relative left-1/2 -translate-x-1/2"
                      />
                    </div>
                    <div className="absolute bottom-[10px] text-white text-[9px] font-regular-so leading-[10px] left-1/2 -translate-x-1/2 w-full">
                      Nhiệm vụ <br />
                      hàng ngày
                    </div>
                  </div>
                </button>
                <button
                  className="col-span-2 flex items-end"
                  onClick={handleListRewards}
                >
                  <div className="relative top-0">
                    <img src={dataGame?.frame_icon_url} />
                    <div className="absolute w-full top-0">
                      <img
                        src={dataGame?.result_icon_url}
                        className="w-7/12 top-1 relative left-1/2 -translate-x-1/2"
                      />
                    </div>
                    <div className="absolute bottom-[10px] text-white text-[9px] font-regular-so leading-[10px] left-1/2 -translate-x-1/2 w-full">
                      Danh sách <br />
                      trúng thưởng
                    </div>
                  </div>
                </button>
                <div className="col-span-3 relative flex justify-center filter grayscale">
                  <div className="w-full h-[100px] relative">
                    <div className="absolute bottom-3 left-1/2 -translate-x-1/2 w-[100px] h-[100px] bg-watering-can">
                      <div id="box" className={`relative rotation-wateringcan`}>
                        <img
                          src={
                            dataPoint?.already_join && dataGame?.bucket_icon_url
                          }
                          className="w-[100px] relative z-20 right-2"
                        />
                      </div>
                    </div>
                    <div className="absolute bottom-6 w-[100px]"></div>
                    <div className="absolute bottom-0 w-full">
                      <img src={BUTTON} />
                      <div className="absolute font-bold-so left-1/2 -translate-x-1/2 top-[45%] -translate-y-1/2 w-full text-white">
                        X{bucket} xô nước
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-[12px] text-black">
                Gọi điện cho Hotline{" "}
                <a
                  href={`tel:${hotline}`}
                  className="font-bold-so text-[#1E9E52]"
                >
                  {hotline}
                </a>{" "}
                để được hỗ trợ
              </div>
            </div>
          </>
        )}
      </div>
      <LeaderBoard
        frameBow={dataGame?.bow_tie_image_url as string}
        imgTitle={
          // type
          //   ? (dataGame?.result_icon_url as string)
          //   :
          dataGame?.leader_board_icon_url as string
        }
        lstLeaderBoard={lstLeaderBoard}
        showTab={showTab}
        handleOpenBoard={handleOpenBoard}
        title={
          // type ? "DANH SÁCH TRÚNG THƯỞNG" :
          "BẢNG XẾP HẠNG"
        }
        idPeriod={id}
      />
      <Quest
        frameBow={dataGame?.bow_tie_image_url as string}
        imgTitle={dataGame?.quest_icon_url as string}
        showTab={showLeaderBoard}
        handleOpenBoard={handleOpenLeaderBoard}
        lstQuest={lstQuest}
      />
      <TC
        frameBow={dataGame?.bow_tie_image_url as string}
        imgTitle={dataGame?.tnc_icon_url as string}
        showTab={isTc}
        handleOpenTC={handleOpenTC}
        content={contentDetails}
      />
      <OverlayPopup isShow={!dataPoint?.already_join as boolean}>
        <div className="relative top-[150px] left-[200px]" onClick={seedBag}>
          <div className="">
            <div className="animation-seed absolute -top-40 left-[20px]">
              {isSedd && <Water color={"#aa8b27"} />}
            </div>
            <img
              src={dataGame?.seed_bag_icon_url}
              className={`${isseedBag ? "animation-wateringcan" : ""}`}
            />
          </div>
        </div>
      </OverlayPopup>
      <Footer />
    </BackgroundGame>
  );
}
