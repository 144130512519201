import { useNavigate, useParams } from "react-router-dom";
import BACK from "../../assets/image/Header/back-header.png";
import DOWN from "../../assets/image/LeaderBoard/down.png";
import CALENDAR from "../../assets/image/Prize/calendar-select.png";
import { useEffect, useState } from "react";
import COIN from "../../assets/image/coin.png";
import "../../assets/css/PrizeWeekMonth.css";
import PHONE from "../../assets/image/Popup/phone.png";
import { LeaderBoardServices } from "../../services/apiService/LeaderBorad";
import { UserLeaderBoard } from "../../models/LeaderBoard";
import { formatCoin } from "../../utils/formatData";
import Loading from "../../components/Loading/Loading";
import { GameServices } from "../../services/apiService/GameService";
import { userLocal } from "../../services/localService/localService";

interface dataExample {
  week: string;
  race_id: number;
}

export default function ListLeaderBoard() {
  const { id, prize, race_id } = useParams();

  const [current, setCurrent] = useState(prize === "month" ? "2" : "1");
  const [race_type, setRaceType] = useState(prize ? prize : "week");
  const [listBoard, setlistBoard] = useState([]);
  const [leaderBoard, setLeaderBoard] = useState<UserLeaderBoard>();
  const data_bg = JSON.parse(localStorage.getItem("DATA_LEADER_BOARD") || "{}");
  const [listRace, setListRace] = useState<any>([]);
  let { token } = userLocal.get();
  const navigation = useNavigate();
  const handleBack = () => {
    navigation(`/prize-game/${id}`);
  };
  const hanldeClick = (e: any, status_gifts: boolean) => {
    setStatus(true);
    setlistBoard([]);
    setCurrent(e.target.id);

    if (e.target.id === "1") {
      setRaceType("week");
      getRaceMonth(id as string, "week");
    } else {
      setRaceType("month");
      getRaceMonth(id as string, "month");
    }
  };
  const getData = (campaign_id: string, token: any) => {
    GameServices.GameDetailsApi(campaign_id, token)
      .then((res: any) => {
        localStorage.setItem("DATA_LEADER_BOARD", JSON.stringify(res));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const { hotline } = JSON.parse(
    localStorage.getItem("HOME_DATA_LOCAL") || "{}"
  );
  const [isStatus, setStatus] = useState(false);
  const [err, setError] = useState<string>("");
  const [race_id_select, setRaceId] = useState(race_id ? race_id : "0");
  const setDataPage = (res: any) => {
    setRaceId(res.race_id);
    setlistBoard(res?.leader_boards);
    let customer = res?.leader_boards?.filter(
      (x: UserLeaderBoard) => x.is_consumer_top === true
    );
    if (res?.leader_boards?.length !== 0) {
      setError("");
      if (res?.consumer_leader_board?.top !== undefined) {
        setLeaderBoard(res?.consumer_leader_board);
      } else {
        setLeaderBoard(undefined);
      }
    } else {
      setError("");
      setLeaderBoard(undefined);
      setlistBoard(customer);
    }
  };
  const getLeaderBoard = (id: string, race_type: string, race_id: string) => {
    setStatus(true);
    if (prize) {
      LeaderBoardServices.LeaderBoardResult(id as string, race_type, race_id)
        .then((res: any) => {
          setDataPage(res);
        })
        .catch((err) => {
          setLeaderBoard(undefined);
          setlistBoard([]);
          setError(err);
          console.log(err);
        })
        .finally(() => {
          setStatus(false);
        });
    } else {
      LeaderBoardServices.LeaderBoard(id as string, race_type, race_id)
        .then((res: any) => {
          setDataPage(res);
        })
        .catch((err) => {
          setLeaderBoard(undefined);
          setlistBoard([]);
          setError(err);
          console.log(err);
        })
        .finally(() => {
          setStatus(false);
        });
    }
  };
  const getRaceMonth = (id: string, race_type: string) => {
    LeaderBoardServices.Race(id as string, race_type)
      .then((res: any) => {
        let arr = [];
        for (let i = 0; i < res.races?.length; i++) {
          let raceNameAndDuration =
            res.races[i]?.race_name + " - " + res?.races[i]?.race_duration;
          let dataExample = {
            week: raceNameAndDuration,
            race_id: res.races[i]?.race_id,
          };
          arr.push(dataExample);
        }
        setListRace(arr);
        getLeaderBoard(id as string, race_type, arr[0].race_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getRace = (id: string, race_type: string) => {
    LeaderBoardServices.Race(id as string, race_type)
      .then((res: any) => {
        let arr = [];
        for (let i = 0; i < res.races?.length; i++) {
          let raceNameAndDuration =
            res.races[i]?.race_name + " - " + res?.races[i]?.race_duration;
          let dataExample = {
            week: raceNameAndDuration,
            race_id: res.races[i]?.race_id,
          };
          arr.push(dataExample);
        }
        setListRace(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getLeaderBoard(id as string, race_type, race_id_select);
    getRace(id as string, race_type);
    getData(id as string, token);
  }, []);
  const handlePrize = () => {
    navigation(`/list-prize-seasons/${id}`);
  };
  const handleWeek = (e: any) => {
    setStatus(true);
    setRaceId(e.target.value);
    setlistBoard([]);
    getLeaderBoard(id as string, race_type, e.target.value);
  };
  const handleListGift = () => {
    navigation("/list-gift/3");
  };
  return (
    <div className="relative top-0 w-full">
      <div className="flex justify-start relative">
        <img
          src={BACK}
          className="w-10 relative top-3 left-3 z-40"
          onClick={handleBack}
        />
      </div>
      <div className="absolute top-0 bg-[#DEFAFD] min-h-screen w-full">
        <img
          src={
            prize
              ? data_bg?.race_winning_image_url
              : data_bg?.race_banner_image_url
          }
        />
        <div className="w-full flex justify-center mt-2">
          <div className="w-[60%] tab-weekmonth flex justify-between items-center align-middle font-light-so">
            <div className="flex justify-center flex-auto">
              <button
                key="1"
                id={"1"}
                disabled={current === `${1}`}
                onClick={(e) => hanldeClick(e, true)}
                className="font-semibold-mon mt-[6px] leading-4"
              >
                Tuần
              </button>
            </div>
            <div className="flex justify-center flex-auto">
              <button
                key="2"
                id={"2"}
                disabled={current === `${2}`}
                onClick={(e) => hanldeClick(e, false)}
                className="font-semibold-mon mt-[6px] leading-4"
              >
                Tháng
              </button>
            </div>
          </div>
        </div>
        <div className="relative w-6/12 left-1/2 -translate-x-1/2  bg-[#7dcfeb] flex justify-center mt-3 text-white">
          <div className="absolute top-1/2 -translate-y-1/2 -left-7">
            <img src={CALENDAR} />
          </div>
          <select
            className="text-white rounded-lg bg-[#7dcfeb] input-hidden border-select-day px-10 py-2"
            onChange={(e) => handleWeek(e)}
            defaultValue={race_id_select}
            value={race_id_select}
          >
            {listRace?.length !== 0 && (
              <>
                {listRace?.map((res: dataExample) => (
                  <option key={res.race_id} value={res.race_id}>
                    {res.week}
                  </option>
                ))}
              </>
            )}
          </select>
          <div className="absolute top-1/2 -translate-y-1/2 -right-8">
            <img src={DOWN} className="w-3" />
          </div>
        </div>
        <div className="absolute w-full px-3 mt-4 containerNotify__background-listPrize h-[52%] ">
          <ul className="bg-white border-select-day w-full rounded-xl py-2 containerNotify__background-listPrize h-[60%]">
            {err !== "" && (
              <div className="text-center h-full flex items-center justify-center">
                {err}
              </div>
            )}
            {isStatus && (
              <div className="relative mt-2 px-5">
                <Loading />
              </div>
            )}
            {listBoard?.map((res: UserLeaderBoard) => (
              <div className="px-2 py-1">
                <div
                  className={`grid grid-cols-12 px-2 py-3 gap-0 rounded-lg`}
                  style={{
                    backgroundColor: res.color,
                    border: `2px solid ${res.border_color}`,
                  }}
                >
                  <div className="col-span-2 flex justify-center">
                    <div className="relative top-0 w-10 h-10">
                      <img
                        src={res.image_url}
                        className="relative top-0 w-10 h-10"
                      />
                      <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 font-semibold-so">
                        {res.top}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-9">
                    <div className="grid grid-cols-12">
                      <div className="text-[#2D4E68] col-span-8 font-semibold-so">
                        {res.consumer_name}
                      </div>
                      <div className="text-[#4E9ABB] col-span-4 font-semibold-so flex justify-end">
                        {formatCoin(res.point)}
                      </div>
                    </div>
                    <div className="grid grid-cols-12">
                      <div className="text-[#59C2DD] col-span-4 font-light-so text-[12px]">
                        {res.consumer_phone}
                      </div>
                      <div className="text-[12px] text-[#242E49] col-span-8 flex justify-end">
                        {res.lasted_update}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 flex justify-start items-center">
                    <div className="text-[#4E9ABB] font-semibold-so">
                      <img src={COIN} />
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="h-28"></div>
          </ul>
        </div>
        <div className="flex justify-center flex-col pb-10 fixed -left-0 bottom-0 w-full px-6 fade-background">
          {leaderBoard && (
            <div className="relative top-0 grid grid-cols-12 border-select-day px-2 py-1 rounded-xl bg-profile-board">
              <div className="relative top-0 w-12 h-12 col-span-2">
                <img src={leaderBoard.image_url} className="w-12 h-12" />
                <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 font-semibold-so">
                  {leaderBoard.top}
                </div>
              </div>
              <div className="col-span-9">
                <div className="grid grid-cols-12">
                  <div className="text-[16px] col-span-8 font-semibold-so text-[#242E49]">
                    {leaderBoard.consumer_name}
                  </div>
                  <div className="text-[#4E9ABB] col-span-4 font-semibold-so flex items-center justify-end">
                    {leaderBoard.point}
                  </div>
                </div>
                <div className="grid grid-cols-12">
                  <div className="text-[#59C2DD] col-span-4 font-light-so text-[14px] flex items-center justify-start">
                    {leaderBoard.consumer_phone}
                  </div>
                  <div className="text-[12px] col-span-8 text-[#242E49] flex items-center justify-end">
                    {leaderBoard.lasted_update}
                  </div>
                </div>
              </div>
              <div className="col-span-1">
                <div className="text-[#4E9ABB] font-semibold-so flex items-center h-full">
                  <img src={COIN} />
                </div>
              </div>
            </div>
          )}

          <div className="relative z-10 grid grid-cols-2 gap-2 text-white pt-3">
            <div className="col-span-1">
              <button
                className="w-full rounded-xl leading-5 py-[18px] shadow-item bg-[#2D4E68]"
                onClick={handleListGift}
              >
                Mã dự thưởng
              </button>
            </div>
            <div className="col-span-1">
              <button
                className="w-full rounded-xl leading-5 py-2 shadow-item bg-[#81BEB4]"
                onClick={handlePrize}
              >
                Danh sách
                <br />
                trúng thưởng
              </button>
            </div>
          </div>
          <div className="absolute z-0 bottom-2 w-full left-0 flex justify-center">
            <div className="flex items-center mt-20">
              <div>
                <img src={PHONE} />
              </div>
              <div className="text-[#2D4E68] font-light-so ml-2">
                Liên hệ Hotline{" "}
                <span className="font-semibold-so">
                  <a href={`tel:${hotline}`}>{hotline}</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
