import ItemGameExchangePoint from "./ItemGameExchangePoint";
import NoPromotion from "../../assets/image/ListPromotion/no-promotion.jpg";
import { useNavigate, useParams } from "react-router-dom";
import BACK from "../../assets/image/Header/back-header.png";
import { Fragment, useEffect, useState } from "react";
import { getAllBannerHome } from "../../redux/slices/HomeSlice";
import { setAuthorization } from "../../services/apiService/configURL";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/stores/store";
import { userLocal } from "../../services/localService/localService";
import { ListGame } from "../../models/HomePage";
import HeaderFrame from "../../components/HeaderFrame/HeaderFrame";
import { ListReceiptServices } from "../../services/apiService/ListReceiveService";

export default function ListGameExchangePoint() {
  const dispatch = useDispatch<AppDispatch>();
  let { token } = userLocal.get();
  const { type, id } = useParams();
  const lstTabCate = [
    {
      category_id: "",
      category_image_url: "",
      category_name: "Tất cả",
    },
  ];
  const lstTab = [
    {
      channel_code: "Tất cả",
      channel_id: "",
      channel_image_url: "",
      channel_name: "Tất cả",
    },
  ];
  const [listChanel, setChanel] = useState<any>([]);
  const [listCampaign, setCampaign] = useState([]);
  setAuthorization(token);
  const [listTab, setListTab] = useState<any>(lstTab);
  const navigation = useNavigate();
  useEffect(() => {
    dispatch(getAllBannerHome());
    if (type === "chanel_id") {
      getCampaign(id as string);
    } else {
      getCampaignByBrand(id as string);
    }
  }, []);
  const getCampaign = (item: string | number) => {
    ListReceiptServices.GetCampaignChanelGift(item ? item : "")
      .then((res: any) => {
        console.log(res);
        setCampaign(res?.campaigns);
        const mergeChanel = [...lstTab, ...res?.channels];
        setChanel(mergeChanel);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCampaignByBrand = (item: string | number) => {
    console.log(item);
    ListReceiptServices.GetCampaignBrandGift(item ? item : "")
      .then((res: any) => {
        setCampaign(res?.campaigns);
        const mergeChanel = [...lstTabCate, ...res?.categories];
        setChanel(mergeChanel);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleBack = () => {
    document.body.style.overflow = "unset";
    navigation("/");
  };
  const [selectedItem, setSelectedItem] = useState<any>(id ? id : "");
  const handleSelect = (item: any) => {
    console.log(item);
    setSelectedItem(item?.channel_id);
    getCampaign(item?.channel_id);
  };
  const handleSelectCat = (item: any) => {
    console.log(item);
    setSelectedItem(item?.category_id);
    getCampaignByBrand(item?.category_id);
  };
  return (
    <div className="">
      <HeaderFrame
        footer=""
        title={"Khuyến mãi siêu hời"}
        handleBack={handleBack}
        iconBack={BACK}
      >
        <div className="overhand pb-1 mt-5">
          {type === "chanel_id"
            ? listChanel?.map((res: any, index: number) => (
                <div
                  className="border-tab-exchange py-1 px-5 mr-2 font-medium-so"
                  key={index}
                  onClick={() => handleSelect(res)}
                  style={{
                    backgroundColor:
                      selectedItem?.toString() === res?.channel_id?.toString()
                        ? "#5BC2DC"
                        : "#ffffff",
                    color:
                      selectedItem?.toString() === res?.channel_id?.toString()
                        ? "#ffffff"
                        : "",
                  }}
                >
                  {res?.channel_name}
                </div>
              ))
            : listChanel?.map((res: any, index: number) => (
                <div
                  className="border-tab-exchange py-1 px-5 mr-2 font-medium-so"
                  key={index}
                  onClick={() => handleSelectCat(res)}
                  style={{
                    backgroundColor:
                      selectedItem?.toString() === res?.category_id?.toString()
                        ? "#5BC2DC"
                        : "#ffffff",
                    color:
                      selectedItem?.toString() === res?.category_id?.toString()
                        ? "#ffffff"
                        : "",
                  }}
                >
                  {res?.category_name}
                </div>
              ))}
        </div>

        <div>
          {listCampaign?.length !== 0 ? (
            <Fragment>
              {listCampaign?.map((res: ListGame, index: any) => (
                <ItemGameExchangePoint
                  id={res?.campaign_id}
                  type={res?.game_type}
                  img={res?.banner_image_url}
                  campaign_id={res?.campaign_id}
                  name={res?.campaign_name}
                  campaign_time={res?.campaign_time}
                  campaign_type={res?.campaign_type}
                />
              ))}
            </Fragment>
          ) : (
            <div className="text-center">
              <img src={NoPromotion} />
              <div>
                Hiện tại chưa có chương trình khuyến mãi.
                <br />
                Chúng tôi sẽ cập nhật sớm nhất có thể.
                <br />
                Cảm ơn bạn đã quan tâm!
              </div>
            </div>
          )}
        </div>
      </HeaderFrame>
    </div>
  );
}
